import React, { useEffect, useContext } from 'react';
import { ChartContainer, TableHeading, ScrollableDiv } from './CiTradesTable.style';
import { ciTradeTableData } from '../../../../../../api/services';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { ICiTradeTableData } from '../../../../../../Types/types';

const CiTradesTable: React.FC = () => {
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).ciTradeAndVolume;

  const mapData: ICiTradeTableData[] = analyticsState.ciTradeTableData;
  const sum = mapData?.reduce((acc, item) => acc + item.QTY, 0)?.toFixed(2);
  const tableFormattedData = [...mapData].map((el) => {
    return {
      ...el,
      QTY: Math.round(Number(el.QTY) * 100) / 100,
    };
  });

  const columnNames = [
    'CPTY',
    'CPTY_DESC',
    'YEAR',
    'MONTH',
    'QTY',
    'NCF_CATEGORY',
    'DEAL_TYPE_NAME',
    'NCF_SCOPE',
  ];
  const columns = columnNames.map((key) => ({
    header: key.replace(/_/g, ' '),
    accessorKey: key,
  }));

  useEffect(() => {
    const fetchData = async () => {
      let response;
      try {
        response = await ciTradeTableData(analyticsState.ciTradeFilteredValues);
        // Use the analyticsDispatch function to call setNcfQuarterwise
        analyticsDispatch({
          type: 'setCiTradeTableData',
          payload: response.data,
        });
      } catch (error) {
        // Log error once toast feature is implemented
      }
    };

    fetchData();
  }, [analyticsDispatch, analyticsState.ciTradeFilteredValues]); // Add analyticsDispatch to the dependency array

  return (
    <ChartContainer>
      <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
        <TableHeading>CI Trades Table</TableHeading>
      </Flexbox>
      <ScrollableDiv>
        <BaseTable
          columns={columns}
          data={tableFormattedData}
          size='large'
          data-testid='base-table'
        />
        Total {sum}
      </ScrollableDiv>
    </ChartContainer>
  );
};

export default CiTradesTable;
