import {
  IBiaFilterData,
  IBiaFilterOptions,
  IBiaGroupAffData,
  IBiaInactiveCptyData,
  IBiaReportPayload,
  IBiaReports,
  IBiaReportsAction,
  IBiaUncatCptyAsiaAustraliaData,
  IBiaUncatCptyEuropeAmericaData,
  IBiaValidationCheckData,
} from '../../Types/types';

const BiaReportsActionHandlers: Record<
  string,
  // eslint-disable-next-line no-unused-vars
  (state: IBiaReports, payload: IBiaReportPayload) => IBiaReports
> = {
  setBiaValidationCheckData: (state, payload) => ({
    ...state,
    biaValidationCheckData: payload as IBiaValidationCheckData[],
  }),
  setBiaUncatCptyEuropeAmericaData: (state, payload) => ({
    ...state,
    biaUncatCptyEuropeAmericasData: payload as IBiaUncatCptyEuropeAmericaData[],
  }),
  setBiaUncatCptyAsiaAustraliaData: (state, payload) => ({
    ...state,
    biaUncatCptyAsiaAustraliaData: payload as IBiaUncatCptyAsiaAustraliaData[],
  }),
  setBiaInactiveCptyData: (state, payload) => ({
    ...state,
    biaInactiveCptyData: payload as IBiaInactiveCptyData[],
  }),
  setBiaGroupAffData: (state, payload) => ({
    ...state,
    biaGroupAffData: payload as IBiaGroupAffData[],
  }),
  setIsValidationDataLoading: (state, payload) => ({
    ...state,
    isValidationDataLoading: payload as boolean,
  }),
  setIsGroupAffDataLoading: (state, payload) => ({
    ...state,
    isGroupAffDataLoading: payload as boolean,
  }),
  setIsInactiveCptyDataLoading: (state, payload) => ({
    ...state,
    isInactiveCptyDataLoading: payload as boolean,
  }),
  setIsUncatCptyDataLoading: (state, payload) => ({
    ...state,
    isUncatCptyDataLoading: payload as boolean,
  }),
  setBiaFilterData: (state, payload) => ({
    ...state,
    biaFilterData: payload as IBiaFilterData,
  }),
  setIsLoading: (state, payload) => ({
    ...state,
    isLoading: payload as boolean,
  }),
  setIsBiaFilterOptionsLoading: (state, payload) => ({
    ...state,
    isBiaFilterOptionsLoading: payload as boolean,
  }),
  setBiaFilterOptions: (state, payload) => ({
    ...state,
    biaFilterOptions: payload as IBiaFilterOptions,
  }),
  setIsNcfServerError: (state, payload) => ({
    ...state,
    isNcfServerError: payload as boolean,
  }),
  setNcfServerErrorMessage: (state, payload) => ({
    ...state,
    ncfServerErrorMessage: payload as string,
  }),
};
export const BiaReportsReducers = (state: IBiaReports, action: IBiaReportsAction) => {
  if (Object.hasOwn(BiaReportsActionHandlers, action.type)) {
    const handler = BiaReportsActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
