import React, { useContext, useEffect, useState } from 'react'; // Step 1: Import useState
import { Grid, Flexbox, Divider } from '@sede-x/shell-ds-react-framework';
import 'leaflet/dist/leaflet.css';
import { ReconFilterSection } from './DataReconciliationFilterSection';
import { AppContext } from '../../../../Context/AppContext';
import NcfReportingHomeHeader from '../NcfReportingUtils/NcfReportingHomeHeader/NcfReportingHomeHeader';

import {
  reconReportTotalVolume,
  reconReportVolumeTableData,
  reconReportNcfCategoryMismatchTableData,
  reconReportLastRefreshedDate,
  reconFilterOptions,
} from '../../../../api/services';
import Loader from '../../../Loader/Loader';
import DataReconciliationSideCard from './ReconReportingCharts/DataReconciliationSideCard/DataReconciliationSideCard';

import DeltaTable from './ReconReportingCharts/DeltaTable/DeltaTable';
import { ReconDataSourceFilter } from './ReconReportingCharts/ReconDataSourceFilter/ReconDataSourceFilter';
import PptReportingBarChart from './ReconReportingCharts/ReconTotalValuesTable/ReconTotalValuesTable';

export const DataReconciliationHome = () => {
  const { dispatch: appDataDispatch } = useContext(AppContext).appData;
  const { state: reconState, dispatch: reconDispatch } = useContext(AppContext).reconReports;
  const [isLoading, setIsLoading] = useState(true); // Step 3: Initialize isLoading state

  useEffect(() => {
    appDataDispatch({ type: 'setCurrentPage', payload: '' });
  }, [appDataDispatch]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Step 4: Set isLoading to true at the start
      try {
        if (reconState.ReconfilteredValues.reportType === 'NCF_CATEGORY_MISMATCH') {
          const categoryMismatchResponse = await reconReportNcfCategoryMismatchTableData(
            reconState.ReconfilteredValues,
          );
          reconDispatch({
            type: 'setReconReportNcfCategoryMismatchTableData',
            payload: categoryMismatchResponse.data,
          });
        } else {
          const volumeTableDataResponse = await reconReportVolumeTableData(
            reconState.ReconfilteredValues,
          );
          reconDispatch({
            type: 'setReconReportVolumeTableData',
            payload: volumeTableDataResponse.data,
          });
        }
      } catch (error) {
        // Log error once toast feature is implemented
      } finally {
        setIsLoading(false); // Step 4: Set isLoading to false after fetching data
      }
    };
    fetchData();
  }, [reconDispatch, reconState.ReconfilteredValues]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Step 4: Set isLoading to true at the start
      try {
        const reconFilterOptionsResponse = await reconFilterOptions({
          dataSource: reconState.ReconfilteredValues.dataSource,
        });
        const totalVolumeResponse = await reconReportTotalVolume(reconState.ReconfilteredValues);
        const lastRefreshedDateResponse = await reconReportLastRefreshedDate(
          reconState.ReconfilteredValues,
        );

        reconDispatch({ type: 'setReconReportTotalVolume', payload: totalVolumeResponse.data });

        reconDispatch({
          type: 'setReconReportLastRefreshedDate',
          payload: lastRefreshedDateResponse.data,
        });

        // Dispatch the setReconFilterOptions action
        reconDispatch({
          type: 'setReconFilterOptions',
          payload: reconFilterOptionsResponse.data,
        });
      } catch (error) {
        // Log error once toast feature is implemented
      } finally {
        setIsLoading(false); // Step 4: Set isLoading to false after fetching data
      }
    };

    fetchData();
  }, [reconDispatch, reconState.ReconfilteredValues.dataSource]);

  const breadcrumbItems = [
    { name: 'Ncf Reporting', path: '/' },
    { name: 'Data Reconciliation Report' },
  ];

  return (
    <div style={{ height: '100%', background: '#F5F5F5' }} data-testid='recon-reporting'>
      {isLoading && <Loader loader={true} message={'Loading...'} />}

      <Flexbox gap='24px 24px' style={{ marginLeft: '15px', justifyContent: 'start' }}>
        <ReconFilterSection />
        <Grid gap='10px'>
          <Grid.Cell>
            <Flexbox justifyContent='space-between'>
              <NcfReportingHomeHeader
                title='Data Reconciliation Report'
                description='Detailed Data Reconciliation Report'
                breadcrumbItems={breadcrumbItems}
              />
              <DataReconciliationSideCard />
            </Flexbox>
          </Grid.Cell>
          <Divider size='medium' direction='horizontal' />
          <Grid.Cell>
            <ReconDataSourceFilter />
          </Grid.Cell>
          <Grid.Cell>
            <PptReportingBarChart />
          </Grid.Cell>

          <Grid.Cell>
            <DeltaTable />
          </Grid.Cell>
          {/* <Grid.Cell>
            <Flexbox gap="10px">
              <PptInScopeVsOutScope tradeName="Natural Gas" />
              <PptInScopeVsOutScope tradeName="Power" />
            </Flexbox>
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox gap="10px">
              <PptInScopeNcfCatgChart tradeName="Natural Gas" />
              <PptInScopeNcfCatgChart tradeName="Power" />
            </Flexbox>
          </Grid.Cell> */}
        </Grid>
      </Flexbox>
    </div>
  );
};
