/* eslint-disable no-unused-vars */
import {
  IEditFeatureData,
  Action,
  IEditFeatureDataTypes,
  IEditTableData,
  IAuditTableData,
  IEditAuditFilterOptions,
  OptionI,
  IEditDirtyData,
} from '../../Types/types';

const appDataActionHandlers: Record<
  string,
  (state: IEditFeatureData, payload: IEditFeatureDataTypes) => IEditFeatureData
> = {
  setUserMail: (state, payload) => ({
    ...state,
    userMail: payload as string,
  }),
  setAllFieldsValidated: (state, payload) => ({
    ...state,
    allFieldsValidated: payload as boolean,
  }),
  setTableData: (state, payload) => ({ ...state, tableData: payload as IEditTableData[] }),
  setMasterTableData: (state, payload) => ({
    ...state,
    masterTableData: payload as IEditTableData[],
  }),
  setAuditTableData: (state, payload) => ({
    ...state,
    auditTableData: payload as IAuditTableData[],
  }),
  setSpinner: (state, payload) => ({ ...state, spinner: payload as boolean }),
  setCurrentPage: (state, payload) => ({ ...state, currentPage: payload as string }),
  setTableSelected: (state, payload) => ({ ...state, tableSelected: payload as string }),
  setFilteredOptions: (state, payload) => ({
    ...state,
    filteredOptions: payload as IEditAuditFilterOptions,
  }),
  setMarketOptions: (state, payload) => ({ ...state, marketOptions: payload as OptionI[] }),
  setNcfCategoryOptions: (state, payload) => ({
    ...state,
    ncfCategoryOptions: payload as OptionI[],
  }),
  setBuySellOptions: (state, payload) => ({
    ...state,
    buySellOptions: payload as OptionI[],
  }),
  setFilteredRegion: (state, payload) => ({
    ...state,
    filteredRegion: payload as string,
  }),
  setRole: (state, payload) => ({ ...state, role: payload as string[] }),
  setIsRoleNcf: (state, payload) => ({ ...state, isRoleNcf: payload as boolean }),
  setUserRegions: (state, payload) => ({ ...state, userRegions: payload as string[] }),
  setUsername: (state, payload) => ({ ...state, username: payload as string }),
  setMail: (state, payload) => ({ ...state, mail: payload as string }),
  setRecordCount: (state, payload) => ({ ...state, recordCount: payload as number }),
  setAuditPaginationData: (state, payload) => ({
    ...state,
    auditPaginationData: payload as IAuditTableData[],
  }),
  setViewEditPaginationData: (state, payload) => ({
    ...state,
    viewEditPaginationData: payload as IEditTableData[],
  }),
  setMasterPaginationData: (state, payload) => ({
    ...state,
    masterPaginationData: payload as IEditTableData[],
  }),
  setCurrentPaginationNum: (state, payload) => ({
    ...state,
    currentPaginationNum: payload as number,
  }),
  setHasTableChangesPublished: (state, payload) => ({
    ...state,
    hasTableChangesPublished: payload as boolean,
  }),
  setDirtyData: (state, payload) => ({ ...state, dirtyData: payload as IEditDirtyData[] }),
  setErroredRows: (state, payload) => ({ ...state, erroredRows: payload as IEditTableData[] }),
  setPublishRows: (state, payload) => ({ ...state, publishRows: payload as IEditTableData[] }),
  setMasterErroredRows: (state, payload) => ({
    ...state,
    masterErroredRows: payload as IEditTableData[],
  }),
  setEditableCol: (state, payload) => ({ ...state, editableCol: payload as string[] }),
  setDefaultPageSize: (state, payload) => ({
    ...state,
    defaultPageSize: payload as number,
  }),
  setIsGlobalUser: (state, payload) => ({ ...state, isGlobalUser: payload as boolean }),

  setCanUserManipulateData: (state, payload) => ({
    ...state,
    canUserManipulateData: payload as boolean,
  }),
  setCanUserAddAndSubmitSnapshot: (state, payload) => ({
    ...state,
    canUserAddAndSubmitSnapshot: payload as boolean,
  }),
  setCanUserChangeSnapshotStatus: (state, payload) => ({
    ...state,
    canUserChangeSnapshotStatus: payload as boolean,
  }),
  setShowAllRegions: (state, payload) => ({
    ...state,
    showAllRegions: payload as boolean,
  }),
  setShowConfigRulePage: (state, payload) => ({
    ...state,
    showConfigRulePage: payload as boolean,
  }),
  setShowSnapshotActions: (state, payload) => ({
    ...state,
    showSnapshotActions: payload as boolean,
  }),
  setIsAustraliaUser: (state, payload) => ({
    ...state,
    isAustraliaUser: payload as boolean,
  }),
  setShowSectoralPage: (state, payload) => ({
    ...state,
    showSectoralpage: payload as boolean,
  }),
  setIsPpaUser: (state, payload) => ({
    ...state,
    isPpaUser: payload as boolean,
  }),

  setIsLoggedLoginEvent: (state, payload) => ({
    ...state,
    isLoggedLogin: payload as boolean,
  }),
  setIsAnyInvalidComment: (state, payload) => ({
    ...state,
    isAnyInvalidComment: payload as boolean,
  }),
  setIsAnyInvalidCountryName: (state, payload) => ({
    ...state,
    isAnyInvalidCountryName: payload as boolean,
  }),
  setIsAnyInvalidQuantity: (state, payload) => ({
    ...state,
    isAnyInvalidQuantity: payload as boolean,
  }),
  setShowReport: (state, payload) => ({
    ...state,
    showReport: payload as boolean,
  }),
  setShowAuditReport: (state, payload) => ({
    ...state,
    showAuditReport: payload as boolean,
  }),
  setIsGetData: (state, payload) => ({
    ...state,
    isGetData: payload as boolean,
  }),
};

export const DataEditReducers = (state: IEditFeatureData, action: Action) => {
  if (Object.hasOwn(appDataActionHandlers, action.type)) {
    const handler = appDataActionHandlers[action.type];
    return handler(state, action.payload as IEditFeatureDataTypes);
  }
  return state;
};
