import React, { useEffect, useState, useContext } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AppContext } from '../../../../../../Context/AppContext';
import { MapContainer, Spacer, GeoChartContainer } from './TopCtpyBarChart.style';
import { topCounterParty } from '../../../../../../api/services';
import Loader from '../../../../../Loader/Loader';

const TopCtpyBarChart = () => {
  const { dispatch } = useContext(AppContext).appData;
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: 'setCurrentPage', payload: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await topCounterParty(analyticsState.cptyFilteredValues);
        analyticsDispatch({
          type: 'setTopCounterParty',
          payload: response.data,
        });
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        // Log error once toast feature is implemented
      }
    };

    fetchData();
  }, [analyticsDispatch, analyticsState.cptyFilteredValues]);

  const mapData = data.map((item: { CounterpartyName: string; Quantity: number }) => ({
    name: item?.CounterpartyName,
    y: item?.Quantity,
  }));

  const options = {
    chart: {
      type: 'bar',
      height: 800,
    },
    title: {
      text: 'Top 20 Counterparties Chart',
      align: 'left',
    },
    xAxis: {
      categories: mapData.map((item) => item.name),
    },
    yAxis: {
      title: {
        text: `Quantity (${analyticsState?.cptyFilteredValues?.uom})`,
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      labelFormatter() {
        return `Year: ${
          analyticsState?.cptyFilteredValues?.year?.length &&
          analyticsState?.cptyFilteredValues?.year[0]
        }`;
      },
    },
    tooltip: {
      pointFormat: 'Quantity: {point.y}',
    },
    plotOptions: {
      series: {
        pointWidth: 20,
        pointPadding: 0.2, // Padding between each column or bar, defaults to 0.1
        groupPadding: 0.2, // Padding between each value groups, in x axis units, defaults to 0.2
        dataLabels: {
          enabled: true,
          format: '{point.y}', // Format to show the value
        },
      },
    },
    series: [
      {
        name: 'Counterparties',
        data: mapData,
        color: '#0097A9',
      },
    ],
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader loader={true} message={''} />;
    } else if (mapData.length === 0) {
      return <p>No data available for selected filters</p>;
    } else {
      return (
        <HighchartsReact highcharts={Highcharts} options={options} data-testid='mock-highcharts' />
      );
    }
  };

  return (
    <MapContainer data-testid='map-container'>
      <Spacer>
        <GeoChartContainer data-testid='mock-highcharts'>{renderContent()}</GeoChartContainer>
      </Spacer>
    </MapContainer>
  );
};

export default TopCtpyBarChart;
