import {
  TANSO,
  REPORTONE,
  POWER_INTENSITY_MODEL,
  POWER_INTENSITY_MODEL_RES,
  PLANNING_ONE,
  PARIG_FINANCE_REPORTING,
  TANSO_FUTURE,
} from '../../../api/constants';

interface CarbonBudgetLine {
  TRADE_COMMODITY_NAME: string;
  CARBON_BUDGET: number;
  CARBON_EMISSION: number;
  QUANTITY: number;
  TRADE_YEAR: number;
  SOURCE: string;
}
interface YearTypeData {
  yearType: string;
  TRADE_YEAR: number;
  TRADE_COMMODITY_NAME: string;
  CARBON_BUDGET: number;
  CARBON_EMISSION: number;
  QUANTITY: number;
  SOURCE: string;
  year?: number;
  type?: string;
}

interface ChartInsideData {
  CARBON_EMISSION?: number;
  CARBON_BUDGET?: number;
  QUANTITY?: number;
  SOURCE?: string;
  TRADE_COMMODITY_NAME: string;
  TRADE_YEAR?: number;
  type: string;
  year?: number;
  yearType?: string;
}

interface ChartData {
  type: string;
  name: string;
  data: ChartInsideData[];
  color?: string;
  dashStyle?: string;
}

interface YearTypeValue {
  type: string;
  year: number;
}
interface DataEntry {
  yearType?: string;
  TRADE_YEAR?: number;
  TRADE_COMMODITY_NAME: string;
  CARBON_BUDGET?: number;
  CARBON_EMISSION?: number;
  QUANTITY?: number;
  SOURCE?: string;
  year: number;
  type: string;
}
interface IyearTypeOtherObject {
  yearType: string;
  year: number;
  type: string;
  TRADE_COMMODITY_NAME: string;
  CARBON_EMISSION: number;
  CARBON_BUDGET: number;
  QUANTITY: number;
}
// Historical Data
const historicalSources = new Set([
  TANSO,
  REPORTONE,
  POWER_INTENSITY_MODEL,
  POWER_INTENSITY_MODEL_RES,
]);
const planningSources = new Set([PLANNING_ONE, PARIG_FINANCE_REPORTING, TANSO_FUTURE]);
let groupedData: ChartData[] = [];
let updatedGroupedData: ChartData[] = [];
let uniqueSourcesArray: string[] = [''];
let uniqueYears: YearTypeValue[] = [{ year: 2021, type: '' }];

const determineYearType = (source: string, TRADE_YEAR: number): string | undefined => {
  if (historicalSources.has(source)) {
    return `${TRADE_YEAR}-historical`;
  } else if (planningSources.has(source)) {
    return `${TRADE_YEAR}-plan`;
  }
  return `${TRADE_YEAR}-historical`;
};

/* Function to map CarbonBudgetLine to YearTypeData */
const mapToYearTypeData = (line: CarbonBudgetLine): YearTypeData => {
  const yearType =
    determineYearType(line.SOURCE, line.TRADE_YEAR) ?? `${line.TRADE_YEAR}}-historical`;
  return {
    yearType,
    TRADE_YEAR: line.TRADE_YEAR,
    TRADE_COMMODITY_NAME: line.TRADE_COMMODITY_NAME,
    CARBON_BUDGET: line.CARBON_BUDGET,
    CARBON_EMISSION: line.CARBON_EMISSION,
    QUANTITY: line.QUANTITY,
    SOURCE: line.SOURCE,
  };
};

/* Custom sorting function */
const customSort = (a: string, b: string): number => {
  const [yearA, typeA] = a.split('-');
  const [yearB, typeB] = b.split('-');

  if (yearA === yearB) {
    /* If years are the same, place historical entries first */
    if (typeA === 'historical') {
      return -1;
    }
    if (typeB === 'historical') {
      return 1;
    }
  }

  /* Sort by year in ascending order */
  return parseInt(yearA, 10) - parseInt(yearB, 10);
};
const mergeData = (data1: DataEntry[], data2: DataEntry[]): DataEntry[] => {
  const updatedData1 = [...data1];
  data2.forEach((entry2) => {
    const { year, type, TRADE_COMMODITY_NAME } = entry2;
    const matchingEntry = updatedData1.find(
      (entry) =>
        entry.year === year &&
        entry.type === type &&
        entry.TRADE_COMMODITY_NAME === TRADE_COMMODITY_NAME,
    );

    if (!matchingEntry) {
      // If no matching entry exists in data1, add a new entry with specific values
      const newEntry: DataEntry = {
        yearType: `${year}-${type}`,
        TRADE_YEAR: year,
        TRADE_COMMODITY_NAME,
        CARBON_EMISSION: 0,
        CARBON_BUDGET: 0,
        QUANTITY: 0,
        SOURCE: '',
        year,
        type,
      };
      updatedData1.push(newEntry);
    }
  });

  // Sort the merged data by year
  updatedData1.sort((a, b) => a.year - b.year);

  return updatedData1;
};

const getYearTypeOtherObjects = (yearTypeOtherObjects: DataEntry[]) => {
  return yearTypeOtherObjects.reduce((accumulator: DataEntry[], current: DataEntry) => {
    const { yearType, TRADE_COMMODITY_NAME } = current;
    const existingEntry = accumulator.find(
      (entry) => entry.yearType === yearType && entry.TRADE_COMMODITY_NAME === TRADE_COMMODITY_NAME,
    );

    if (existingEntry) {
      // If an entry already exists, update the aggregated fields
      existingEntry.CARBON_EMISSION =
        (existingEntry.CARBON_EMISSION ?? 0) + (current.CARBON_EMISSION ?? 0);
      existingEntry.QUANTITY = (existingEntry.QUANTITY ?? 0) + (current.QUANTITY ?? 0);
      existingEntry.CARBON_BUDGET = existingEntry.CARBON_BUDGET ?? 0;
    } else {
      // Otherwise, add a new entry to the accumulator
      accumulator.push(current);
    }
    return accumulator;
  }, []);
};

const sortMergedData = (mergedData: DataEntry[]) => {
  return mergedData.sort((a, b) => {
    if (a.TRADE_COMMODITY_NAME < b.TRADE_COMMODITY_NAME) {
      return -1;
    }
    if (a.TRADE_COMMODITY_NAME > b.TRADE_COMMODITY_NAME) {
      return 1;
    }
    if (a.year < b.year) {
      return -1;
    }
    if (a.year > b.year) {
      return 1;
    }
    if (a.type === 'historical' && b.type === 'plan') {
      return -1;
    }
    if (a.type === 'plan' && b.type === 'historical') {
      return 1;
    }
    return 0;
  });
};

const transformGroupData = (sorted: ChartInsideData[], groupedChartData: ChartData[]) => {
  const newgroupedData = groupedChartData;
  sorted.forEach((itemToAdd: ChartInsideData) => {
    const tradeCommodityName = itemToAdd.TRADE_COMMODITY_NAME;

    // Check if there's an existing entry for this TRADE_COMMODITY_NAME
    const existingEntryIndex =
      newgroupedData && newgroupedData.length > 0
        ? newgroupedData.findIndex((entry) => entry.name === tradeCommodityName)
        : -1;

    if (existingEntryIndex !== -1) {
      // If an entry exists, push the new data to its 'data' array
      newgroupedData[existingEntryIndex].data.push(itemToAdd);
    } else {
      // If no entry exists, create a new entry and add it to 'groupedData'
      newgroupedData.push({
        type: 'area',
        name: tradeCommodityName,
        data: [itemToAdd],
      });
    }
  });
  return newgroupedData;
};
export const fetchCarbonBudget = (
  carbonBudgetData: CarbonBudgetLine[],
): {
  uniqueSources: string[];
  uniqueYears: YearTypeValue[];
  groupedData: ChartData[];
} => {
  groupedData = [];
  updatedGroupedData = [];
  uniqueSourcesArray = [];
  uniqueYears = [];

  let carbonBudgetReceivedData = [];
  carbonBudgetReceivedData = [...carbonBudgetData];
  if (carbonBudgetReceivedData.length > 0) {
    const sourcesSet: Set<string> = new Set(
      carbonBudgetReceivedData.map((item: { SOURCE: string }) => item.SOURCE),
    );
    uniqueSourcesArray = Array.from(sourcesSet);

    /* Map carbonBudgetData to YearTypeData */
    const combineYearType: YearTypeData[] = carbonBudgetReceivedData.map(mapToYearTypeData);

    /* Extract the unique "yearType" values using Set */
    const uniqueYearTypes = new Set<string>(
      combineYearType.map((item) => item.yearType).filter(Boolean),
    );

    /* Convert the Set to an array (if needed) */
    const uniqueYearTypesArray: string[] = Array.from(uniqueYearTypes);

    /* Sort the data using the custom sort function */
    uniqueYearTypesArray.sort(customSort);

    /* map year and type key with other objects */
    const yearTypeOtherObjects: IyearTypeOtherObject[] = combineYearType.map((item) => {
      const newItem = item;
      const [yearStr, type] = newItem.yearType.split('-');
      return { ...newItem, year: parseInt(yearStr, 10), type };
    });

    const reducedyearTypeOtherObjects = getYearTypeOtherObjects(yearTypeOtherObjects);

    const yearTypeObjects: { year: number; type: string }[] = uniqueYearTypesArray.map((str) => {
      const [yearStr, type] = str.split('-');
      return { year: parseInt(yearStr, 10), type };
    });
    const data1 = reducedyearTypeOtherObjects;
    uniqueYears = yearTypeObjects;

    // Commodity types to combine
    const commodityTypes = Array.from(new Set(data1.map((entry) => entry.TRADE_COMMODITY_NAME)));

    // Generate combinations and append to the existing array
    const combinationsForYearTypeCommodity = [];

    for (const yearEntry of yearTypeObjects) {
      for (const typeEntry of commodityTypes.map((type) => ({ TRADE_COMMODITY_NAME: type }))) {
        combinationsForYearTypeCommodity.push({ ...yearEntry, ...typeEntry });
      }
    }

    const data2 = combinationsForYearTypeCommodity;

    /* sort according to data 1 i.e all data and map missing data for all commodity for all years from data2 */
    const mergedData = mergeData(data1, data2);
    /* sort according to commodity and its year and then type */
    const sorted = sortMergedData(mergedData);

    groupedData = transformGroupData(sorted, groupedData);
  }

  // Assuming `groupedData` is an array, you can add `carbonIntensityData` to it like this:
  updatedGroupedData = groupedData;

  return {
    uniqueSources: uniqueSourcesArray,
    uniqueYears,
    groupedData: updatedGroupedData,
  };
};
