import React, { useEffect, useState } from 'react';
import { Dates, DatesHeading, SideCard } from './DateSideCard.style';
import { lastRefesh } from '../../../../../api/services';
import moment from 'moment';

const DateSideCard = () => {
  const [dates, setDates] = useState({
    ASIA: '',
    AMERICAS: '',
    EUROPE: '',
    AUSTRALIA: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await lastRefesh();
        setDates(result.data);
      } catch (error) {
        // Add catch block when toast is implemented
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString: string) => {
    return moment(dateString).format('DD MMM YYYY, hh:mm:ss A');
  };

  return (
    <SideCard data-testid="side-card">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          style={{ marginRight: '4px', marginBottom: '8px' }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.6667 10.0001C16.6667 13.682 13.682 16.6667 10.0001 16.6667C6.31818 16.6667 3.33341 13.682 3.33341 10.0001C3.33341 6.31818 6.31818 3.33341 10.0001 3.33341C13.682 3.33341 16.6667 6.31818 16.6667 10.0001ZM18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001ZM9.99805 5.83174C10.2282 5.83174 10.4147 6.01828 10.4147 6.2484L10.4147 10.0686L13.3628 13.0166C13.5255 13.1793 13.5255 13.4432 13.3628 13.6059L13.0681 13.9005C12.9054 14.0632 12.6416 14.0632 12.4789 13.9005L9.31818 10.7398C9.22452 10.6634 9.16471 10.5471 9.16471 10.4168V6.2484C9.16471 6.01828 9.35126 5.83174 9.58138 5.83174H9.99805Z"
            fill="#292929"
          />
        </svg>
        <DatesHeading>As of Date</DatesHeading>
      </div>{' '}
      <Dates> EU (UTC): {dates.EUROPE && formatDate(dates.EUROPE)} </Dates>
      <Dates>AM (CST): {dates.AMERICAS && formatDate(dates.AMERICAS)} </Dates>
      <Dates> AS (JST): {dates.ASIA && formatDate(dates.ASIA)} </Dates>
      <Dates> AU (JST): {dates.ASIA && formatDate(dates.AUSTRALIA)} </Dates>
    </SideCard>
  );
};

export default DateSideCard;
