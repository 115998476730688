import { IReconData } from '../../Types/types';

export const ReconReportsInitialState: IReconData = {
  ReconfilterOptions: {
    DATA_SOURCE: ['AM_NG', 'AM_PW', 'EU_ALIGN', 'EU_GROUP', 'AS_PW', 'AU_NG'],
    YEAR: [],
    MONTH: [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER',
    ],
    TRADE_TYPE: [],
    BUY_SELL: ['BUY', 'SELL'],
    NCF_CATEGORY: [
      'Group Affiliate',
      'Financial Traders/ marketers',
      'Solar offtake',
      'Energy Retailer/ Aggregator',
      'Environmental',
      'Structured Retailer',
      'Utilities - trading activities',
      'Direct customer',
      'CCA',
      'Wholesale Traders',
      'ISO',
      'Joint Venture',
      'Commodity broker /exchange',
      'Hydro offtake',
      'Storage',
      'Gas production/distribution',
      'Utilities - new',
      'Wind offtake',
      'Utilities - end user sales/ generation',
      'TSO/DSO',
    ],
    RULE_APPLIED: ['YES', 'NO'],
    UNIT: ['MMBTU', 'MWH', 'TBTU', 'TWH'],
  },
  ReconfilteredValues: {
    year: ['2024'],
    month: [],
    uom: 'TWh',
    transactionType: 'SELL',
    dataSource: 'AM_NG',
    tradeType: 'NG',
    reportType: 'DELTA_DETAILS',
    pageNumber: 1,
    ncfCategory: [],
    pageSize: 10,
  },
  reconReportTotalVolume: {
    TANSO: {
      TOTAL_VOLUME: 100,
      TOTAL_COUNT: 1,
    },
    ETRM: {
      TOTAL_VOLUME: 100,
      TOTAL_COUNT: 1,
    },
    DELTA: {
      TOTAL_VOLUME: 0,
      TOTAL_COUNT: 0,
    },
  },
  reconReportDefaultTotalVolume: {
    TANSO: {
      TOTAL_VOLUME: 100,
      TOTAL_COUNT: 1,
    },
    ETRM: {
      TOTAL_VOLUME: 100,
      TOTAL_COUNT: 1,
    },
    DELTA: {
      TOTAL_VOLUME: 0,
      TOTAL_COUNT: 0,
    },
  },
  reconReportVolumeTableData: {
    totalItems: 1704,
    totalPages: 171,
    pageNumber: 1,
    data: [
      {
        YEAR: 2023,
        MONTH: 'April',
        NCF_CATEGORY: 'Commodity broker /exchange',
        CPTY: 'ICEENDEX',
        BUY_SELL: 'BUY',
        TANSO_VOLUME: 1.4852061206,
        ETRM_VOLUME: 1.4852061206,
        DELTA_VOLUME: 0,
        TANSO_COUNT: 1,
        ETRM_COUNT: 1,
      },
    ],
    totals: {
      TANSO: {
        TOTAL_VOLUME: 6927.03,
        TOTAL_COUNT: 1704,
      },
      ETRM: {
        TOTAL_VOLUME: 6936.06,
        TOTAL_COUNT: 1704,
      },
      DELTA: {
        TOTAL_VOLUME: -9.030000000000655,
        TOTAL_COUNT: 0,
      },
    },
  },
  reconReportNcfCategoryMismatchTableData: [
    {
      CPTY: '24/7 TRAD',
      ETRM: 'Utilities',
      TANSO: 'Utilities',
    },
  ],
  reconReportLastRefreshedDate: {
    ETRM_DATE: '01 Jan 2024',
    TANSO_DATE: '26 Jun 2024',
    LOG: {
      REGION: 'EUROPE',
      COMMODITY: 'POWER & GAS',
      ERROR_HEADING: 'Data Ingestion Failed',
      ERROR_DESCRIPTION:
        'A data load failure has happened in Tanso and we are working on fixing the issue. This error message will disappear once the issue is resolved.',
      RUN_DATE: '2024-03-11T14:24:46.257Z',
      RUN_STATUS: 'Failed',
    },
  },
};

export type IReconInitialState = typeof ReconReportsInitialState;
