import styled from 'styled-components';
export const OverAllContainer = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  padding-top: 10px;
  // margin: 10px;
  flex: 1;
  width: 33.3%;
`;

export const ViewDetailsButton = styled.div`
  float: right;
  margin-right: 20px;
  padding-top: 10px;
  margin-bottom: 10px;
`;

export const ViewDetailsButtonPlaceHolder = styled.div`
  float: right;
  height: 40px;
  margin-right: 20px;
  padding-top: 10px;
  margin-bottom: 10px;
`;

export const ScenerioModellingContainer = styled.div`
  background: rgb(245, 245, 245);
  padding: 5px 20px 10px 20px;
`;

export const Title = styled.div`
  font-size: 24px;
  color: #343434;
  font-weight: 600;
  font-family: 'Shell Font';
  line-height: 28px;
  font-style: normal;
  fill: #343434;
  margin-top: 13px;
`;

export const SubTitle = styled.div`
  color: #757575;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  font-family: 'Shell Font';
  fill: #757575;
  margin-top: 13px;
`;

export const ToggleText = styled.div`
  margin-left: unset;
  margin-right: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const VisualScenerioFlex = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 20px;
  height: 100%;
  width: 100%;
  background: #f5f5f5;
`;

export const DividerPadding = styled.div`
  display: flex;
  padding: 10px;
`;
