import React from 'react';
import { IUserInfo } from '../../Types/types';
import AppRouter from '../../../commonUtils/AppRouter';
import { LoginPage } from '../../../commonUtils/authPages/LoginPage/LoginPage';
import { UnAuthUserPage } from '../../../commonUtils/authPages/UnAuthUserPage/UnAuthUserPage';

interface RoleBasedUserProps {
  role: string;
  user: IUserInfo;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

const RoleBasedUser: React.FC<RoleBasedUserProps> = ({ role, user, login, logout }) => {
  switch (role) {
    case 'unauthorized':
      return <UnAuthUserPage role={role} user={user} />;
    case '':
      return <LoginPage login={login} />;
    default:
      return <AppRouter user={user} logout={logout} />;
  }
};

export default RoleBasedUser;
