import { configureStore } from '@reduxjs/toolkit';
import commodityDetailsReducer from './commodityDetails/commodityDetailsSlice';
import datavisualizationReducer from './datavisualization/datavisualizationSlice';
import dashboardReducer from './dashboard/dashboardSlice';

const store = configureStore({
  reducer: {
    commodityOverview: commodityDetailsReducer,
    datavisualization: datavisualizationReducer,
    dashboard: dashboardReducer,
  },
});

export default store;

export type AppDispatch = typeof store.dispatch;
