import { point5 } from '../../../constants';
import { ShellFont } from '../../../utils';

const plotBands = [
  {
    color: 'rgba(128, 128, 128, 0.3)', // Gray background for historical section
    from: 0,
    to: 0,
    label: {
      text: 'Actuals',
      style: {
        fontFamily: ShellFont,
        fontSize: '11px',
        fontWeight: '600',
        lineHeight: '16px',
        letterSpacing: '0em',
        textAlign: 'right',
        color: '#343434',
      },
      verticalAlign: 'bottom', // Label below the y axis
      y: 40, // Adjust label position
    },
  },

  {
    color: 'white', // White background for plan section
    from: -point5,
    to: 20,
    label: {
      text: 'Plan',
      style: {
        fontFamily: ShellFont,
        fontSize: '11px',
        fontWeight: '600',
        lineHeight: '16px',
        letterSpacing: '0em',
        textAlign: 'right',
        color: '#343434',
      },
      verticalAlign: 'bottom', // Label below the y axis
      y: 40, // Adjust label position
    },
  },
];

export const getPlotBands = (uniqueYears: { year: number; type: string }[]) => {
  let lastActualIndex = -1;
  for (let i = uniqueYears.length - 1; i >= 0; i--) {
    if (uniqueYears[i].type === 'historical') {
      lastActualIndex = i;
      break;
    }
  }

  return lastActualIndex !== -1
    ? plotBands.map((plotBand, index) => {
        const modifyValues = (fromModifier: number, toModifier: number) => ({
          ...plotBand,
          from: fromModifier,
          to: toModifier,
        });

        return index === 0
          ? modifyValues(-point5, lastActualIndex + point5) // Modify the second object
          : modifyValues(lastActualIndex + point5, uniqueYears.length - 1 - point5); // Modify both objects
      })
    : [{ ...plotBands[1], from: -point5, to: uniqueYears.length - 1 - point5 }]; // Return only Plan band.
};
