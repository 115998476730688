import React from 'react';
import { Breadcrumb } from '@sede-x/shell-ds-react-framework';
import { Title, SubTitle } from './TopCtpyHeader.style';

const TopCtpyHeader = () => {
  const breadcrumbItems = [
    { name: 'Master Data Records', path: '/' },
    { name: 'Analytics Home', path: '/ncf-reporting' },
    { name: 'Top Counter Parties' },
  ];

  return (
    <div style={{ marginTop: '46px' }}>
      <Breadcrumb items={breadcrumbItems} />
      <Title data-testid='title'>Top 20 Counterparties</Title>
      <SubTitle data-testid='subtitle'>List of top Counterparties based on Quantity</SubTitle>
    </div>
  );
};

export default TopCtpyHeader;
