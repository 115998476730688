import React, { useEffect, useContext } from 'react';
import { ChartContainer, TableHeading, ScrollableDiv } from './TopCtpyTable.style';
import { topCounterParty } from '../../../../../../api/services';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';

const TopCtpyTable: React.FC = () => {
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;

  const columnNames = [
    'CounterpartyName',
    'NcfCategory',
    'Country',
    'Year',
    'Quantity',
    'Percentage',
  ];

  const columns = columnNames.map((key) => ({
    header: key === 'Quantity' ? `Quantity (${analyticsState?.cptyFilteredValues?.uom})` : key,
    accessorKey: key,
  }));

  useEffect(() => {
    const fetchData = async () => {
      let response;
      try {
        response = await topCounterParty(analyticsState.cptyFilteredValues);
        analyticsDispatch({
          type: 'setCounterpartyData',
          payload: response.data,
        });
      } catch (error) {
        // Log error once toast feature is implemented
      }
    };

    fetchData();
  }, [analyticsDispatch, analyticsState.cptyFilteredValues]);

  return (
    <ChartContainer>
      <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
        <TableHeading>Top 20 Counterparties Table</TableHeading>
      </Flexbox>
      <ScrollableDiv>
        <BaseTable
          columns={columns}
          data={analyticsState.counterpartyData}
          size='large'
          data-testid='base-table'
          stickyHeader={true}
          maxHeight={500}
        />
      </ScrollableDiv>
    </ChartContainer>
  );
};

export default TopCtpyTable;
