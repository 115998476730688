/* eslint-disable no-unused-vars */
import {
  AnalyticsSnapshotReportLineChartItem,
  IAnalyticsSnapshotReportDataError,
  IAnalyticsSnapshotReportFilteredValues,
  IAnalyticsSnapshotReportTableData,
  IAnalyticsSnapshotReportType,
  IAnalyticsSnapshotReportTypePayload,
  IFilterAnalyticsSnapshotReportOptions,
  IVolumeByRegionChartData,
  IVolumeByRegionDetailsTableData,
  IVolumeByRegionTableData,
} from '../../Types/IAnalyticsSnapshotReportType';
import { IAnalyticsSnapshotReportAction } from '../../Types/types';

const AnalyticsSnapshotReportActionHandlers: Record<
  string,
  (
    state: IAnalyticsSnapshotReportType,
    payload: IAnalyticsSnapshotReportTypePayload,
  ) => IAnalyticsSnapshotReportType
> = {
  setError: (state, payload) => ({
    ...state,
    error: payload as IAnalyticsSnapshotReportDataError,
  }),
  setAnalyticsSnapshotReportChartData: (state, payload) => ({
    ...state,
    analyticsSnapshotReportChartData: payload as AnalyticsSnapshotReportLineChartItem[],
  }),
  setAnalyticsSnapshotReportTableData: (state, payload) => ({
    ...state,
    analyticsSnapshotReportTableData: payload as IAnalyticsSnapshotReportTableData[],
  }),
  setAnalyticsSnapshotReportFilterOptions: (state, payload) => ({
    ...state,
    analyticsSnapshotReportFilterOptions: payload as IFilterAnalyticsSnapshotReportOptions,
  }),
  setAnalyticsSnapshotReportFilteredValues: (state, payload) => {
    return {
      ...state,
      analyticsSnapshotReportFilteredValues: payload as IAnalyticsSnapshotReportFilteredValues,
    };
  },
  setAnalyticsSnapshotVolumeByRegionChartData: (state, payload) => ({
    ...state,
    volumeByRegionChartData: {
      ...state.volumeByRegionChartData,
      ...payload,
    } as IVolumeByRegionChartData,
  }),
  setAnalyticsSnapshotVolumeByRegionTableData: (state, payload) => ({
    ...state,
    volumeByRegionTableData: payload as IVolumeByRegionTableData,
  }),
  setAnalyticsSnapshotReportsData: (state, payload) => ({
    ...state,
    analyticsSnapshotReportFilterOptions: {
      ...state.analyticsSnapshotReportFilterOptions,
      snapshotReports:
        payload as unknown as IFilterAnalyticsSnapshotReportOptions['snapshotReports'],
    },
  }),
  setRegionVolumeDetailsTableData: (state, payload) => {
    return {
      ...state,
      volumeByRegionDetailsTableData: payload as IVolumeByRegionDetailsTableData[],
    };
  },
};
export const AnalyticsSnapshotReportReducers = (
  state: IAnalyticsSnapshotReportType,
  action: IAnalyticsSnapshotReportAction,
) => {
  if (Object.hasOwn(AnalyticsSnapshotReportActionHandlers, action.type)) {
    const handler = AnalyticsSnapshotReportActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
