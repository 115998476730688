import React from 'react';
import styles from './CustomTable.module.css';
import { ITableColumns, ITableRowData } from '../../Types/types';
import { uptoTwoDecimal } from '../../utils';
import {
  Table,
  THead,
  TBody,
  TH as Th,
  TR as Tr,
  TD as Td,
} from '@sede-x/shell-ds-react-framework';

interface TableProps {
  columns: ITableColumns[];
  rowsData: ITableRowData[];
}

const CustomTable: React.FC<TableProps> = ({ columns, rowsData }) => {
  const renderHead = () => {
    return (
      <Tr>
        {columns.map((col) => (
          <Th key={col.label.toString()}>{col.label}</Th>
        ))}
      </Tr>
    );
  };

  const renderRows = () => {
    return rowsData.map((rowData: ITableRowData) => (
      <Tr key={rowData.seIpuRegion?.toString() ?? rowData.TRADE_COMMODITY_NAME?.toString()}>
        <Td width='120px'>{rowData.seIpuRegion ?? rowData.TRADE_COMMODITY_NAME}</Td>
        <Td width='60px'>{uptoTwoDecimal(rowData.latest_estimate)}</Td>
        <Td width='60px'>{uptoTwoDecimal(rowData.plan)}</Td>
        <Td width='60px'>{uptoTwoDecimal(rowData.delta)}</Td>
      </Tr>
    ));
  };

  return (
    <div className={styles['table-container']} data-testid='table'>
      <Table size='small'>
        <THead>{renderHead()}</THead>
        <TBody>{renderRows()}</TBody>
      </Table>
    </div>
  );
};

export default CustomTable;
