import React, { useContext } from 'react';
import {
  TotalValuesContainer,
  TotalValues01,
  TableHeading,
  Text,
  Text1,
  NavigationCross,
} from './DeltaTableTotalValues.style';
import { AppContext } from '../../../../../../Context/AppContext';

const DeltaTableTotalValues = () => {
  const { state: reconState } = useContext(AppContext).reconReports;
  const {
    TANSO: { TOTAL_VOLUME: TANSO_TOTAL_VOLUME, TOTAL_COUNT: TANSO_TOTAL_COUNT },
    ETRM: { TOTAL_VOLUME: ETRM_TOTAL_VOLUME, TOTAL_COUNT: ETRM_TOTAL_COUNT },
    DELTA: { TOTAL_VOLUME: DELTA_TOTAL_VOLUME, TOTAL_COUNT: DELTA_TOTAL_COUNT },
  } = reconState.reconReportVolumeTableData.totals;

  const {uom} = reconState.ReconfilteredValues

  return (
    <TotalValuesContainer>
      <TotalValues01>
        <TableHeading>
          <Text>Tanso Total Volume:</Text>
          <Text1>{TANSO_TOTAL_VOLUME} {uom}</Text1>
        </TableHeading>
        <TableHeading>
          <Text>ETRM Total Volume:</Text>
          <Text1>{ETRM_TOTAL_VOLUME} {uom}</Text1>
        </TableHeading>
        <TableHeading>
          <Text>Delta Total Volume:</Text>
          <Text1>{DELTA_TOTAL_VOLUME} {uom}</Text1>
        </TableHeading>
        <TableHeading>
          <Text>Tanso Total Count:</Text>
          <Text1>{TANSO_TOTAL_COUNT}</Text1>
        </TableHeading>
        <TableHeading>
          <Text>ETRM Total Count:</Text>
          <Text1>{ETRM_TOTAL_COUNT}</Text1>
        </TableHeading>
        <TableHeading>
          <Text>Delta Total Count:</Text>
          <Text1>{DELTA_TOTAL_COUNT}</Text1>
        </TableHeading>
        <NavigationCross />
      </TotalValues01>
    </TotalValuesContainer>
  );
};

export default DeltaTableTotalValues;