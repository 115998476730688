/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Grid,
  Tabs,
  Divider,
  Select,
  Button,
  Option,
  Flexbox,
} from '@sede-x/shell-ds-react-framework';
import { ArrowCw } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import { SubRegion } from '..';
import { ALL_REGIONS } from '../../../api/constants';
import { SubTitle } from '../ScenerioModelling.styles';

interface RegionTabsProps {
  selectedRegion: string;
  handleTabChange: (key: string) => void;
  subRegionOptions: SubRegion[];
  selectedSubRegion: string;
  setSelectedSubRegion: (value: string) => void;
  handleRevertAllToOriginal: () => void;
}
const RegionTabs: React.FC<RegionTabsProps> = ({
  selectedRegion,
  handleTabChange,
  subRegionOptions,
  selectedSubRegion,
  setSelectedSubRegion,
  handleRevertAllToOriginal,
}) => {
  const tabitems = [
    { key: ALL_REGIONS, label: ALL_REGIONS },
    { key: 'AMERICAS', label: 'Americas' },
    { key: 'ASIA', label: 'Asia' },
    { key: 'AUSTRALIA', label: 'Australia' },
    { key: 'EUROPE', label: 'Europe' },
    // { key: 'GLOBAL', label: 'Global' }, // uncomment when it needs to be added.
  ];
  return (
    <Grid flow='row'>
      <SubTitle>
        Latest Operating Plan sales volumes of different commodities (aka Base Case). Modify them to
        build a scenario and visualize impact on Lifecycle Carbon Emissions.
      </SubTitle>
      <Grid gap='0' style={{ gridAutoRows: 'auto' }}>
        <Tabs
          data-testid='tab-change'
          items={tabitems}
          activeKey={selectedRegion}
          defaultActiveKey={selectedRegion}
          onChange={handleTabChange}
        />
        <Divider size='medium' direction='horizontal' color='#F5F5F5' />
      </Grid>
      <Flexbox style={{ paddingBottom: '10px' }}>
        <Select
          size='medium'
          allowClear={false}
          value={selectedSubRegion}
          style={{ width: '150px', zIndex: 10 }}
          onChange={(value) => setSelectedSubRegion(value)}
        >
          {subRegionOptions?.map((option) => (
            <Option key={option.id} value={option.value}>
              {option.value}
            </Option>
          ))}
        </Select>

        <Button
          variant='outlined'
          size='medium'
          icon={<ArrowCw />}
          style={{ marginLeft: '10px' }}
          onClick={() => handleRevertAllToOriginal()}
        >
          Reset
        </Button>
      </Flexbox>
    </Grid>
  );
};
export default RegionTabs;
