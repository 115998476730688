import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDashboardState } from './dashboard.types';
import { IGroupNCI, ImportedDeals, PlannedVsCommitted } from '../../Types/types';
import { BUSINESS_PLAN_KEY } from '../../constants';

export const initialState: IDashboardState = {
  plannedVolumeData: [],
  committedVolumeData: [],
  nciData: [],
  importedDeals: [],
  addedImportedDealsId: [],
  tabKey: BUSINESS_PLAN_KEY,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setPlannedVolumeData(state, action: PayloadAction<PlannedVsCommitted[]>) {
      state.plannedVolumeData = action.payload;
    },
    setCommittedVolumeData(state, action: PayloadAction<PlannedVsCommitted[]>) {
      state.committedVolumeData = action.payload;
    },
    setNciData(state, action: PayloadAction<IGroupNCI[]>) {
      state.nciData = action.payload;
    },
    setImportedDealsData(state, action: PayloadAction<ImportedDeals[]>) {
      state.importedDeals = action.payload;
    },
    setAddedImportedDealsId(state, action: PayloadAction<number[]>) {
      state.addedImportedDealsId = action.payload;
    },
    setTabKey(state, action: PayloadAction<string>) {
      state.tabKey = action.payload;
    },
  },
});

export const {
  setPlannedVolumeData,
  setCommittedVolumeData,
  setNciData,
  setImportedDealsData,
  setAddedImportedDealsId,
  setTabKey,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
