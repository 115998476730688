import {
  ICiTradeAndVolumeAction,
  ICiTradeAndVolumeData,
  ICiTradeAndVolumeDataError,
  ICiTradeAndVolumeDataPayload,
  ICiTradeChartData,
  ICiTradeFilteredValues,
  ICiTradeFilterOptions,
  ICiTradeTableData,
} from '../../Types/types';

const CiTradeAndVolumeActionHandlers: Record<
  string,
  // eslint-disable-next-line no-unused-vars
  (state: ICiTradeAndVolumeData, payload: ICiTradeAndVolumeDataPayload) => ICiTradeAndVolumeData
> = {
  setError: (state, payload) => ({
    ...state,
    error: payload as ICiTradeAndVolumeDataError,
  }),
  setCiTradeChartData: (state, payload) => ({
    ...state,
    ciTradeChartData: payload as ICiTradeChartData[],
  }),
  setCiTradeTableData: (state, payload) => ({
    ...state,
    ciTradeTableData: payload as ICiTradeTableData[],
  }),
  setCiTradeFilterOptions: (state, payload) => ({
    ...state,
    ciTradeFilterOptions: payload as ICiTradeFilterOptions,
  }),
  setCiTradeFilteredValues: (state, payload) => ({
    ...state,
    ciTradeFilteredValues: payload as ICiTradeFilteredValues,
  }),
};
export const CiTradeAndVolumeReducers = (
  state: ICiTradeAndVolumeData,
  action: ICiTradeAndVolumeAction,
) => {
  if (Object.hasOwn(CiTradeAndVolumeActionHandlers, action.type)) {
    const handler = CiTradeAndVolumeActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
