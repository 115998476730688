/* eslint-disable no-undef */
import { Dispatch, ReactElement, SetStateAction } from 'react';
import tableStyles from '../../style.module.css';
import {
  APPROVED,
  APPROVED_UI,
  ASIA_JAPAN_SMALLCASE,
  AUSTRALIA_SMALLCASE,
  COMMODITY_NG_SHORT_NAME,
  COMMODITY_POWER_SHORT_NAME,
  DATA_SNAPSHOT_REGIONS,
  NCF_SNAPSHOT_ID,
  NINE,
  REJECTED,
  REJECTED_UI,
  RE_SUBMITTED_FOR_APPROVAL,
  RE_SUBMITTED_FOR_APPROVAL_UI,
  SIX,
  SNAPSHOT_STATUS,
  SUBMITTED_FOR_APPROVAL,
  SUBMITTED_FOR_APPROVAL_UI,
  THOUSAND_FIVE_HUNDRED,
} from '../../../api/constants';
import filterOptions from '../../../api/__ignored__/filterOptions';
import {
  Action,
  IColumnRender,
  IEditFeatureData,
  IFetchSnapshotDataOption,
  ISnapshotData,
} from '../../../Types/types';
import { RadioButton } from '@sede-x/shell-ds-react-framework';
import { RadioButtonSelected } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import { fetchSnapshotData, updateSnapshotStatus } from '../../../api/services';
import { hideSpinner, showSpinner, decorateColName } from '../../Utils/utils';
import { ColumnType } from '@sede-x/shell-ds-react-framework/build/esm/components/DeprecatedTable/Table.types';

export const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
const monthOptions = [
  { value: 'Jan', label: 'Jan' },
  { value: 'Feb', label: 'Feb' },
  { value: 'Mar', label: 'Mar' },
  { value: 'Apr', label: 'Apr' },
  { value: 'May', label: 'May' },
  { value: 'Jun', label: 'Jun' },
  { value: 'Jul', label: 'Jul' },
  { value: 'Aug', label: 'Aug' },
  { value: 'Sep', label: 'Sep' },
  { value: 'Oct', label: 'Oct' },
  { value: 'Nov', label: 'Nov' },
  { value: 'Dec', label: 'Dec' },
];
export const defaultRegionOptions = [
  { value: 'Europe', label: 'Europe' },
  { value: 'Americas', label: 'Americas' },
  { value: ASIA_JAPAN_SMALLCASE, label: ASIA_JAPAN_SMALLCASE },
  { value: 'Australia', label: 'Australia' },
];
export const commodityOptions = filterOptions.commodityOptions;
export const HALFYEARLY = 'Half-Yearly';
export const quarterOption = { value: 'Quarterly', label: 'Quarterly' };
export const halfYearOption = { value: HALFYEARLY, label: HALFYEARLY };
export const monthOption = { value: 'Monthly', label: 'Monthly' };
export const yearlyOption = { value: 'Yearly', label: 'Yearly' };
export const firstHalfYearOption = { value: 'H1', label: 'H1' };
export const secondHalfYearOption = { value: 'H2', label: 'H2' };
export const quarterOneOption = { value: 'Q1', label: 'Q1' };
const quarterTwoOption = { value: 'Q2', label: 'Q2' };
const quarterThreeOption = { value: 'Q3', label: 'Q3' };
const quarterFourOption = { value: 'Q4', label: 'Q4' };

export const getQuarterlyTimePeriodOpts = (
  timePeriodOpts: { value: string; label: string }[],
  timeYearOpted: number,
) => {
  if (timeYearOpted < currentYear) {
    timePeriodOpts.push(quarterTwoOption, quarterThreeOption, quarterFourOption);
  } else if (timeYearOpted === currentYear) {
    if (currentMonth > SIX) {
      timePeriodOpts.push(quarterTwoOption);
    }
    if (currentMonth > NINE) {
      timePeriodOpts.push(quarterThreeOption);
    }
  }
  return timePeriodOpts;
};

export const getMonthyTimePeriodOptions = (
  timePeriodOpts: { value: string; label: string }[],
  timeYearOpted: number,
) => {
  if (timeYearOpted < currentYear) {
    timePeriodOpts = monthOptions;
  }
  if (timeYearOpted === currentYear) {
    for (let i = 0; i < currentMonth - 1; i++) {
      timePeriodOpts.push(monthOptions[i]);
    }
  }
  return timePeriodOpts;
};

export const handleTimePeriodChange = (
  e: string,
  unitOfTime: string,
  timeYear: string,
  setOptions: {
    setPrevTimePeriod: Dispatch<SetStateAction<string>>;
    setIsPrevSnapshotApproved: Dispatch<SetStateAction<boolean>>;
    setShowSnapshotNameGenerateMessage: Dispatch<SetStateAction<boolean>>;
    setTimePeriod: Dispatch<SetStateAction<string>>;
    setIsTimePeriodInvalid: Dispatch<SetStateAction<boolean>>;
  },
) => {
  const {
    setPrevTimePeriod,
    setIsPrevSnapshotApproved,
    setShowSnapshotNameGenerateMessage,
    setTimePeriod,
    setIsTimePeriodInvalid,
  } = setOptions;
  const timePeriodSelected = e;
  setIsPrevSnapshotApproved(true);
  setShowSnapshotNameGenerateMessage(false);
  setPrevTimeString(unitOfTime, timeYear, timePeriodSelected, setPrevTimePeriod);
  setTimePeriod(timePeriodSelected);
  setIsTimePeriodInvalid(false);
};

export const setPrevTimeString = (
  unitOfTime: string,
  timeYear: string,
  timePeriodSelected: string,
  setPrevTimePeriod: Dispatch<SetStateAction<string>>,
) => {
  let str = '';
  const timeUnitFunctions = [
    { condition: 'Yearly', fn: getYearlyTimePeriod },
    { condition: HALFYEARLY, fn: getHalfYearlyTimePeriod },
    { condition: 'Quarterly', fn: getQuarterlyTimePeriod },
    { condition: 'Monthly', fn: getMonthlyTimePeriod },
  ];
  const matchingFunction = timeUnitFunctions.find(({ condition }) => condition === unitOfTime);

  if (matchingFunction) {
    str = matchingFunction.fn(timeYear, timePeriodSelected);
  }
  setPrevTimePeriod(str);
};

export const getYearlyTimePeriod = (timeYear: string) => {
  return `${Number(timeYear) - 1} Yearly`;
};

export const getHalfYearlyTimePeriod = (timeYear: string, timePeriodSelected: string) => {
  if (timePeriodSelected === 'H2') {
    return `${timeYear} H1`;
  }
  return `${Number(timeYear) - 1} H2`;
};

export const getQuarterlyTimePeriod = (timeYear: string, timePeriodSelected: string) => {
  if (timePeriodSelected === 'Q1') {
    return `${Number(timeYear) - 1} Q4`;
  } else {
    return timePeriodSelected.slice(0, 1) + `${Number(timePeriodSelected.slice(1)) - 1}`;
  }
};

export const getMonthlyTimePeriod = (timeYear: string, timePeriodSelected: string) => {
  return getMonthlyInput(timePeriodSelected, timeYear);
};

export const getMonthlyInput = (timePeriodSelected: string, timeYear: string) => {
  const monthSelected = timePeriodSelected.toLowerCase();
  if (monthSelected !== 'jan') {
    const index = monthOptions.findIndex((month) => month.value.toLowerCase() === monthSelected);
    return monthOptions[index - 1].value;
  }
  return `Dec ${Number(timeYear) - 1}`;
};

export const resetModal = (setOptions: {
  setRegion: Dispatch<SetStateAction<string>>;
  setTimeYear: Dispatch<SetStateAction<string>>;
  setUnitOfTime: Dispatch<SetStateAction<string>>;
  setTimePeriod: Dispatch<SetStateAction<string>>;
  setCommodity: Dispatch<SetStateAction<string>>;
  setShowSnapshotNameGenerateMessage: Dispatch<SetStateAction<boolean>>;
  setIsGenerateSnapshotNameSuccess: Dispatch<SetStateAction<boolean>>;
  setIsPrevSnapshotApproved: Dispatch<SetStateAction<boolean>>;
  setPrevTimePeriod: Dispatch<SetStateAction<string>>;
  setIsRegionInvalid: Dispatch<SetStateAction<boolean>>;
  setIsTimeYearInvalid: Dispatch<SetStateAction<boolean>>;
  setIsTimePeriodInvalid: Dispatch<SetStateAction<boolean>>;
  setIsUnitOfTimeInvalid: Dispatch<SetStateAction<boolean>>;
  setIsCommodityInvalid: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    setRegion,
    setTimeYear,
    setUnitOfTime,
    setTimePeriod,
    setCommodity,
    setShowSnapshotNameGenerateMessage,
    setIsGenerateSnapshotNameSuccess,
    setIsPrevSnapshotApproved,
    setPrevTimePeriod,
    setIsRegionInvalid,
    setIsTimeYearInvalid,
    setIsTimePeriodInvalid,
    setIsUnitOfTimeInvalid,
    setIsCommodityInvalid,
  } = setOptions;
  setRegion('');
  setTimeYear('Select');
  setUnitOfTime('Select');
  setTimePeriod('Select');
  setCommodity('');
  setShowSnapshotNameGenerateMessage(false);
  setIsGenerateSnapshotNameSuccess(false);
  setIsPrevSnapshotApproved(true);
  setPrevTimePeriod('');
  //clear all erros.
  setIsRegionInvalid(false);
  setIsTimeYearInvalid(false);
  setIsTimePeriodInvalid(false);
  setIsUnitOfTimeInvalid(false);
  setIsCommodityInvalid(false);
};

export const handleRegionChange = (
  e: string,
  setOptions: {
    setRegion: Dispatch<SetStateAction<string>>;
    setIsRegionInvalid: Dispatch<SetStateAction<boolean>>;
    setCommodity: Dispatch<SetStateAction<string>>;
    setIsRegionAsiaJapan: Dispatch<SetStateAction<boolean>>;
    setShowSnapshotNameGenerateMessage: Dispatch<SetStateAction<boolean>>;
  },
  setIsRegionAustralia: Dispatch<boolean>,
) => {
  const {
    setRegion,
    setIsRegionInvalid,
    setCommodity,
    setIsRegionAsiaJapan,
    setShowSnapshotNameGenerateMessage,
  } = setOptions;
  setRegion(e);
  setIsRegionInvalid(false);
  // if region is Asia-Japan, set Commodity to Power and disable the commodity field
  if (e === ASIA_JAPAN_SMALLCASE) {
    setCommodity(COMMODITY_POWER_SHORT_NAME);
    setIsRegionAsiaJapan(true);
  } else if (e === AUSTRALIA_SMALLCASE) {
    setCommodity(COMMODITY_NG_SHORT_NAME);
    setIsRegionAustralia(true);
  } else {
    setIsRegionAsiaJapan(false);
    setIsRegionAustralia(false);
  }
  setShowSnapshotNameGenerateMessage(false);
};
// getTimePeriodOptions must return only completed time, not currently running month/quarter/HY/Year
export const getTimePeriodOptions = (unitOfTimeSelected: string, timeYear: string) => {
  const timeYearOpted = Number(timeYear);
  let timePeriodOpts: { value: string; label: string }[] = [];
  switch (unitOfTimeSelected) {
    case 'Yearly':
      timePeriodOpts.push(yearlyOption);
      break;
    case HALFYEARLY:
      timePeriodOpts.push(firstHalfYearOption);
      if (timeYearOpted < currentYear) {
        timePeriodOpts.push(secondHalfYearOption);
      }
      break;
    case 'Quarterly':
      timePeriodOpts.push(quarterOneOption);
      timePeriodOpts = getQuarterlyTimePeriodOpts(timePeriodOpts, timeYearOpted);
      break;
    case 'Monthly':
      timePeriodOpts = getMonthyTimePeriodOptions(timePeriodOpts, timeYearOpted);
      break;
    default:
      break;
  }
  return timePeriodOpts;
};

export const modifySnapshotStatusforUI = (data: ISnapshotData[]) => {
  const finalData = data;
  for (const row of finalData) {
    if (row[SNAPSHOT_STATUS] === RE_SUBMITTED_FOR_APPROVAL) {
      row[SNAPSHOT_STATUS] = RE_SUBMITTED_FOR_APPROVAL_UI;
    } else if (row[SNAPSHOT_STATUS] === SUBMITTED_FOR_APPROVAL) {
      row[SNAPSHOT_STATUS] = SUBMITTED_FOR_APPROVAL_UI;
    } else if (row[SNAPSHOT_STATUS] === APPROVED) {
      row[SNAPSHOT_STATUS] = APPROVED_UI;
    } else if (row[SNAPSHOT_STATUS] === REJECTED) {
      row[SNAPSHOT_STATUS] = REJECTED_UI;
    }
  }
  return finalData;
};

export const getUserRegionShortNameForSnapshot = (state: IEditFeatureData) => {
  const isUserRegionSpecific = state.userRegions[0] !== '';
  const isUserRegionAsia =
    state.userRegions[0] && state.userRegions[0].toLowerCase().includes('asia');

  if (isUserRegionSpecific && !isUserRegionAsia && state.userRegions[0]) {
    return DATA_SNAPSHOT_REGIONS[
      state.userRegions[0].toUpperCase() as keyof typeof DATA_SNAPSHOT_REGIONS
    ];
  } else if (isUserRegionSpecific && isUserRegionAsia) {
    return DATA_SNAPSHOT_REGIONS['ASIA-JAPAN'];
  } else {
    return '';
  }
};

export const resetTable = (
  setPublishPaginationData: Dispatch<SetStateAction<ISnapshotData[]>>,
  setTotalCount: Dispatch<SetStateAction<number>>,
  setCheckedID: Dispatch<SetStateAction<number>>,
) => {
  setPublishPaginationData([]);
  setTotalCount(0);
  setCheckedID(-1);
};

export const updateSnapshotData = async (
  pageNumber: number,
  dispatch: Dispatch<Action>,
  setStateVars: {
    setCurrentPaginationNumber: Dispatch<SetStateAction<number>>;
    userRegionShortName: string;
    setPublishPaginationData: Dispatch<SetStateAction<ISnapshotData[]>>;
    setTotalCount: Dispatch<SetStateAction<number>>;
    setCheckedID: Dispatch<SetStateAction<number>>;
    setCols: Dispatch<SetStateAction<string[]>>;
    setIsServiceError: Dispatch<SetStateAction<boolean>>;
  },
) => {
  const {
    setCurrentPaginationNumber,
    userRegionShortName,
    setPublishPaginationData,
    setTotalCount,
    setCheckedID,
    setCols,
    setIsServiceError,
  } = setStateVars;
  setCurrentPaginationNumber(pageNumber);
  showSpinner(dispatch);
  const tableOptions: IFetchSnapshotDataOption = {
    pageNumber,
    pageSize: 5,
  };
  if (userRegionShortName) {
    tableOptions.region = userRegionShortName;
  }
  try {
    const snapshotDataResp = await fetchSnapshotData(tableOptions);
    resetTable(setPublishPaginationData, setTotalCount, setCheckedID);
    const snapshotData = modifySnapshotStatusforUI(snapshotDataResp.data.data);
    const count = snapshotDataResp.data.count;
    setPublishPaginationData(snapshotData);
    if (snapshotData.length > 0) {
      setCols(Object.keys(snapshotData[0]));
    }
    setTotalCount(count);
    hideSpinner(dispatch);
    setIsServiceError(false);
  } catch (e) {
    hideSpinner(dispatch);
    setIsServiceError(true);
  }
  hideSpinner(dispatch);
};

export const handleStatusChange = async (
  snapshotStatus: string,
  dispatch: Dispatch<Action>,
  checkedID: number,
  currentPaginationNumber: number,
  setStateVars: {
    setIsSnapshotUpdated: Dispatch<SetStateAction<boolean>>;
    setIsServiceError: Dispatch<SetStateAction<boolean>>;
    setCurrentPaginationNumber: Dispatch<SetStateAction<number>>;
    userRegionShortName: string;
    setPublishPaginationData: Dispatch<SetStateAction<ISnapshotData[]>>;
    setTotalCount: Dispatch<SetStateAction<number>>;
    setCheckedID: Dispatch<SetStateAction<number>>;
    setCols: Dispatch<SetStateAction<string[]>>;
  },
) => {
  const {
    setIsSnapshotUpdated,
    setIsServiceError,
    setCurrentPaginationNumber,
    userRegionShortName,
    setPublishPaginationData,
    setTotalCount,
    setCheckedID,
    setCols,
  } = setStateVars;
  showSpinner(dispatch);
  const options = {
    ncfSnapshotIds: [checkedID],
    snapshotStatus,
  };
  try {
    const updateResponse = await updateSnapshotStatus(options);
    if (updateResponse.data.message === 'Snapshots updated.') {
      setIsSnapshotUpdated(true);
      setTimeout(() => {
        setIsSnapshotUpdated(false);
      }, THOUSAND_FIVE_HUNDRED);
    } else {
      setIsSnapshotUpdated(false);
    }
  } catch (e) {
    setIsServiceError(true);
  }
  await updateSnapshotData(currentPaginationNumber, dispatch, {
    setCurrentPaginationNumber,
    userRegionShortName,
    setPublishPaginationData,
    setTotalCount,
    setCheckedID,
    setCols,
    setIsServiceError,
  });
  hideSpinner(dispatch);
};

export const publishTableCols = (
  cols: string[],
  checkedID: number,
  setCheckedID: Dispatch<SetStateAction<number>>,
) => {
  const colObj: IColumnRender[] = [];

  cols.forEach((val: string) => {
    if (colObj.length === 0) {
      colObj.push({
        key: val,
        width: 60,
        className: tableStyles.snapshotTableCols,
        title: (
          <div>
            <RadioButtonSelected width={20} />
          </div>
        ),
        render: (value: ISnapshotData): ReactElement => (
          <RadioButton
            checked={checkedID === value[NCF_SNAPSHOT_ID]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCheckedID(Number(e.target.value))
            }
            value={value[NCF_SNAPSHOT_ID]}
            disabled={[APPROVED_UI, REJECTED_UI].includes(value[SNAPSHOT_STATUS])}
          />
        ),
      });
    }
    colObj.push({
      dataIndex: val,
      key: val,
      title: <div>{decorateColName(val)}</div>,
      className: tableStyles.snapshotTableCols,
    });
  });
  return colObj as ColumnType<ISnapshotData>[];
};
