import React from 'react';
import { Breadcrumb } from '@sede-x/shell-ds-react-framework';
import { Title, SubTitle } from './CiTradeHeader.style';

const CiTradeHeader = () => {
  const breadcrumbItems = [
    { name: 'Master Data Records', path: '/' },
    { name: 'Analytics Home', path: '/ncf-reporting' },
    { name: 'C&I Trades' },
  ];

  return (
    <div style={{ marginTop: '46px' }}>
      <Breadcrumb items={breadcrumbItems} />
      <Title data-testid='title'>C&I Trades</Title>
      <SubTitle data-testid='subtitle'>C&I Trades</SubTitle>
    </div>
  );
};

export default CiTradeHeader;
