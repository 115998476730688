import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CommodityOverview from '../../CarbonDashboard/components/CommodityOverview';
import DataVisualization from '../../CarbonDashboard/components/DataVisualization/DataVisualization';
import MarginToCarbonRatio from '../../CarbonDashboard/components/MarginToCarbonRatio/MarginToCarbonRatio';
import PortfolioDetails from '../../CarbonDashboard/components/PortfolioDetails';
import ScenerioModelling from '../../CarbonDashboard/components/ScenerioModelling';
import { Toaster } from '../../CarbonDashboard/components/Toast';
import { IUserInfo } from '../../CarbonDashboard/Types/types';
import HeaderBar from '../../Tanso/components/header/HeaderBar';
import { ViewAuditReportPage } from '../../Tanso/components/pages/ViewAuditReportPage/ViewAuditReportPage';
import ViewEditReportPage from '../../Tanso/components/pages/ViewEditReportPage/ViewEditReportPage';
import WithMargin from '../../Tanso/components/Utils/WithMargin';
import MainHeader from '../MainHeader/MainHeader';
import { ConfigureRulesPage } from '../../Tanso/components/pages/ConfigureRulesPage/ConfigureRulesPage';
import { SectoralPerformance } from '../../Tanso/components/pages/SectoralPerformance/SectoralPerformance';
import { PpaInsights } from '../../Tanso/components/pages/PPAInsights/PpaInsights';
import { BiaReportPage } from '../../Tanso/components/pages/NcfReportingPage/BiaReportPage/BiaReportPage';
import { CiTrades } from '../../Tanso/components/pages/NcfReportingPage/CiTrades/CiTrades';
import { DataReconciliationHome } from '../../Tanso/components/pages/NcfReportingPage/DataReconciliation/DataReconciliationHome';
import { PptReportingHome } from '../../Tanso/components/pages/NcfReportingPage/PptReporting/PptReportingHome';
import { SnapshotReport } from '../../Tanso/components/pages/NcfReportingPage/SnapshotReport/SnapshotReport';
import { RegionVolumeDetails } from '../../Tanso/components/pages/NcfReportingPage/AnalyticsHome/RegionVolumeDetails/RegionVolumeDetails';
import { RegionVolumeDetails as SnapshotRegionVolumeDetails } from '../../Tanso/components/pages/NcfReportingPage/SnapshotReport/RegionVolumeDetails/RegionVolumeDetails';
import { AnalyticsHome } from '../../Tanso/components/pages/NcfReportingPage/AnalyticsHome/AnalyticsHome';
import { TopCounterParties } from '../../Tanso/components/pages/NcfReportingPage/TopCounterParties/TopCounterParties';
import { AllCounterParties } from '../../Tanso/components/pages/NcfReportingPage/TopCounterParties/AllCounterParties';
import { DataSnapshotPage } from '../../Tanso/components/pages/PublishDataSnapshotPages/DataSnapshotPage';

interface AppRouterProps {
  user: IUserInfo;
  logout: () => void;
}

const AppRouter: React.FC<AppRouterProps> = ({ user, logout }) => {
  return (
    <div data-testid='app-router'>
      <Toaster />
      <MainHeader
        emailAddress={user.email}
        fullname={user.preferred_username.toUpperCase()}
        logout={logout}
      />
      <Routes>
        <Route path='/' element={<DataVisualization user={user} />} />
        <Route path='/Dashboard' element={<DataVisualization user={user} />} />
        <Route path='/ScenarioModel' element={<ScenerioModelling />} />
        <Route path='/CommodityOverView' element={<CommodityOverview />} />
        <Route path='/PortFolioDetails' element={<PortfolioDetails />} />
        <Route path='/marginToCarbonDetails' element={<MarginToCarbonRatio />} />
        <Route
          path='/viewEditReport'
          element={
            <>
              <HeaderBar />
              <WithMargin>
                <ViewEditReportPage />
              </WithMargin>
            </>
          }
        />
        <Route
          path='/viewauditreport'
          element={
            <>
              <HeaderBar />
              <WithMargin>
                <ViewAuditReportPage />
              </WithMargin>
            </>
          }
        />
        <Route
          path='/configurerules'
          element={
            <>
              <HeaderBar />
              <WithMargin>
                <ConfigureRulesPage />
              </WithMargin>
            </>
          }
        />
        <Route
          path='/sectoralPerformance'
          element={
            <>
              <HeaderBar />
              <WithMargin>
                <SectoralPerformance />
              </WithMargin>
            </>
          }
        />
        <Route
          path='/publishSnapshotReport'
          element={
            <WithMargin>
              <DataSnapshotPage />
            </WithMargin>
          }
        />
        <Route path='/ppa-insights' element={<PpaInsights />} />
        <Route path='/lod-report' element={<BiaReportPage />} />
        <Route path='/ci-trades' element={<CiTrades />} />
        <Route path='/snapshot-report' element={<SnapshotReport />} />
        <Route path='/snapshot-region-volume-details' element={<SnapshotRegionVolumeDetails />} />
        <Route path='/ppt-reporting' element={<PptReportingHome />} />
        <Route path='/data-reconciliation' element={<DataReconciliationHome />} />
        <Route path='/ncf-reporting' element={<AnalyticsHome />} />
        <Route path='/region-volume-details' element={<RegionVolumeDetails />} />
        <Route path='/top-counterparties' element={<TopCounterParties />} />
        <Route path='/all-counterparties' element={<AllCounterParties />} />
      </Routes>
    </div>
  );
};

export default AppRouter;
