import React, { useEffect, useContext, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { counterPartyFilter } from '../../../../api/services';
import { AppContext } from '../../../../Context/AppContext';
import { PPASelectFormField } from '../../PPAInsights/PPAInsightFilterSection/PPASelectFormField';
import { SelectFormField } from '../../../ReUsableComponents/SelectFormField/SelectFormField';
import { REGION_NAMES } from './../../../../api/constants';
import { ICiTradeFilteredValues } from '../../../../Types/types';

export const CiTradeFilterSection = () => {
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).ciTradeAndVolume;
  const filterData = analyticsState.ciTradeFilterOptions;

  const [yearSelected, setYearSelected] = useState('2024');
  const [quarterSelected, setQuarterSelected] = useState('');
  const [regionSelected, setRegionSelected] = useState(REGION_NAMES.ASIA);
  const [unitNameSelected, setUnitNameSelected] = useState('TWH');
  const [commoditySelected, setCommoditySelected] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const response = await counterPartyFilter();
      analyticsDispatch({
        type: 'setCiTradeFilterOptions',
        payload: response.data,
      });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!filterData) {
    return null; // or a loading spinner
  }

  const handleApplyFilters = () => {
    const payload: ICiTradeFilteredValues = {
      year: [yearSelected],
      quarter: [quarterSelected],
      region: regionSelected || REGION_NAMES.ASIA,
      uom: unitNameSelected || '',
      commodity: commoditySelected ? [commoditySelected] : [],
    };

    analyticsDispatch({ type: 'setCiTradeFilteredValues', payload });
  };

  const handleClearFilters = () => {
    setYearSelected('2024');
    setQuarterSelected('');
    setRegionSelected(REGION_NAMES.ASIA);
    setUnitNameSelected('TWH');
    setCommoditySelected('');

    const payload: ICiTradeFilteredValues = {
      year: ['2024'],
      quarter: [],
      region: REGION_NAMES.ASIA,
      commodity: [],
      uom: 'TWH',
    };

    analyticsDispatch({ type: 'setCiTradeFilteredValues', payload });
  };

  const optionsData = filterData.commodity.map((el: { TRADE_NAME: string; TRADE_CODE: string }) => {
    return { label: el.TRADE_NAME, value: el.TRADE_CODE };
  });

  return (
    <div data-testid='filter' style={{ padding: '20px' }}>
      <Flexbox flexDirection='column'>
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            lineHeight: '26px',
            color: '#404040',
            padding: '10px 0',
            marginTop: '26px',
            width: '251px',
          }}
        >
          Filters
        </div>
        <PPASelectFormField
          id='year'
          label='Year'
          testId='Year'
          value={yearSelected}
          onChange={setYearSelected}
          options={filterData.year}
        />
        <PPASelectFormField
          id='quarter'
          label='Quarter'
          testId='Quarter'
          value={quarterSelected}
          onChange={setQuarterSelected}
          options={filterData.quarter}
          mode='multiple'
        />
        <PPASelectFormField
          id='region'
          label='Region'
          testId='Region'
          value={regionSelected}
          onChange={setRegionSelected}
          options={[REGION_NAMES.ASIA]}
        />
        <PPASelectFormField
          id='unitName'
          label='Unit Name'
          testId='Unit Name'
          value={unitNameSelected}
          onChange={setUnitNameSelected}
          options={filterData.unitName}
        />
        <SelectFormField
          id='commodity'
          label='Commodity'
          testId='Commodity'
          value={commoditySelected}
          onChange={setCommoditySelected}
          options={optionsData}
          mode='single'
        />
        <Button className='getDataBtn' data-testid='Apply Filters' onClick={handleApplyFilters}>
          Apply Filters
        </Button>
        <Button
          className='getDataBtn'
          variant='outlined'
          style={{ marginTop: '10px' }}
          data-testid='Clear Filters'
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Flexbox>
      <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.06)' }}></div>
    </div>
  );
};
