import React, { useContext } from 'react';
import { GlobalHeader } from '@sede-x/glass-design-library';
import { Menu, MenuItem } from '@sede-x/shell-ds-react-framework';
import FeedbackLink from '../../CarbonDashboard/components/Feedback/Feedback';
import './MainHeader.css';
import { LOGOUT, SUB_TITLE_DASHBOARD, TITLE_DASHBOARD } from '../../CarbonDashboard/constants';
import { allowedUserMails } from '../../Tanso/api/constants';
import { AppContext } from '../../Tanso/Context/AppContext';
import { IMenuItem } from '../../Tanso/Types/types';
import TabMenu from './TabMenu';
import { Link } from 'react-router-dom';

interface MainHeaderProps {
  fullname: string;
  emailAddress: string;
  logout: () => void;
}

const MainHeader: React.FC<MainHeaderProps> = ({ fullname, emailAddress, logout }) => {
  const { state } = useContext(AppContext).appData;
  const itemRender = ({ path, children }: { path: string; children: React.ReactNode }) => (
    <Link to={path}>{children}</Link>
  );
  const routerMenuItems = () => {
    const baseItems: IMenuItem[] = [
      { key: '0', label: 'Dashboard', path: '/Dashboard' },
      { key: '1', label: 'Commodity Overview', path: '/CommodityOverView' },
      { key: '2', label: 'Scenario Modelling', path: '/ScenarioModel' },
    ];
    const ncfDashboardItem: IMenuItem = {
      key: '3',
      label: 'NCF Dashboard',
      children: [
        { key: '3-1', label: 'Analytics Home', path: '/ncf-reporting' },
        { key: '3-2', label: 'LOD Report', path: '/lod-report' },
        { key: '3-3', label: 'Data Snapshot Report', path: '/snapshot-report' },
        { key: '3-4', label: 'Powerpoint Report', path: '/ppt-reporting' },
        { key: '3-5', label: 'Data Reconciliation Report', path: '/data-reconciliation' },
      ],
    };

    const ncfReportingItem: IMenuItem = {
      label: 'NCF Reporting',
      key: '4',
      children: [
        { key: '4-1', label: 'Edit Report', path: '/viewEditReport' },
        { key: '4-2', label: 'Audit Report', path: '/viewauditreport' },
        { key: '4-3', label: 'Configure Rule', path: '/configurerules' },
        { key: '4-4', label: 'Sectoral Performance', path: '/sectoralPerformance' },
        { key: '4-5', label: 'NCF Snapshot', path: '/publishSnapshotReport' },
      ],
    };

    // Conditionally include the NCF Reporting item based on the email check
    if (allowedUserMails.some((user) => user.userMail === state.userMail)) {
      baseItems.push(ncfDashboardItem);
      baseItems.push(ncfReportingItem);
    }

    return baseItems;
  };

  return (
    <GlobalHeader
      hideGlassHub
      avatar={{
        description: emailAddress,
        title: fullname,
        dropdownOverlay: (
          <Menu>
            <MenuItem key='logout' onClick={logout}>
              {LOGOUT}
            </MenuItem>
          </Menu>
        ),
      }}
      subtitle={SUB_TITLE_DASHBOARD}
      title={TITLE_DASHBOARD}
      centerComponent={<TabMenu items={routerMenuItems()} itemRender={itemRender} />}
      rightComponent={<FeedbackLink />}
    />
  );
};

export default MainHeader;
