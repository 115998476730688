import React from 'react';
import { Grid, Flexbox, Divider } from '@sede-x/shell-ds-react-framework';
import 'leaflet/dist/leaflet.css';
import CiTradesChart from './CiTradeCharts/CiTradeBarChart/CiTradesChart';
import CiTradesTable from './CiTradeCharts/CiTradeTable/CiTradesTable';
import CiTradesHeader from './CiTradeHeader/CiTradeHeader';
import { CiTradeFilterSection } from './CiTradeFilterSection';
import DateSideCard from '../AnalyticsHome/DateSideCard/DateSideCard';

export const CiTrades = () => {
  return (
    <div
      style={{ height: '100%', background: '#F5F5F5' }}
      data-testid="ci-trades"
    >
      <Flexbox
        gap="24px 24px"
        style={{ marginLeft: '15px', justifyContent: 'start' }}
      >
        <CiTradeFilterSection />
        <Grid gap="10px">
          <Grid.Cell>
            <Flexbox justifyContent="space-between">
              <CiTradesHeader />
              <DateSideCard />
            </Flexbox>
          </Grid.Cell>
          <Divider size="medium" direction="horizontal" />
          <Grid.Cell>
            <CiTradesChart />
          </Grid.Cell>
          <Grid.Cell>
            <CiTradesTable />
          </Grid.Cell>
        </Grid>
      </Flexbox>
    </div>
  );
};
