import { useCallback, useEffect, useState } from 'react';
import Highcharts, { XAxisPlotBandsOptions } from 'highcharts';
import Chart from '../../Chart/Chart';
import { ShellFont, plannedVsEstimatedSeriesData, toolTipFormatter } from '../../../utils';
import { fetchplannedVolume, fetchCommittedVolume, fetchKpiMovedDeals } from '../../../api';
import {
  ITransformDataObject,
  PlannedVsCommitted,
  PointsData,
  YearTypeValue,
} from '../../../Types/types';
import { useAppDispatch } from '../../../redux/hooks';
import { hideSpinner, showSpinner } from '../../../redux/datavisualization/datavisualizationSlice';
import {
  CHART_AXIS_STYLE,
  CONTRACTED_VS_PLAN_DISCLAIMER,
  fiveHundred,
  twoThousand,
} from '../../../constants';
import { getPlotBands } from './utils';
import {
  setCommittedVolumeData,
  setPlannedVolumeData,
} from '../../../redux/dashboard/dashboardSlice';
import {
  ToggleButton,
  ToggleButtonDiv,
  ToggleText,
  ViewDetailsDiv,
} from '../OverallPortfolioChart/index.styles';
import { Toggle } from '@sede-x/shell-ds-react-framework';
import { StyledContainer } from '../ScenerioModellingChart/index.styles';
import ViewImportedDeals from '../../ImportedDeals/ImportedDeals';

const LatestEstimateVsPlanned = () => {
  const dispatch = useAppDispatch();
  const [plannedVolume, setPlannedVolume] = useState<PlannedVsCommitted[]>([]);
  const [committedVolume, setCommittedVolume] = useState<PlannedVsCommitted[]>([]);
  const [kpiMovedDeals, setKpiMovedDeals] = useState<PlannedVsCommitted[]>([]);
  const [groupedSeriesData, setGroupedSeriesData] = useState<ITransformDataObject[]>([]);
  const [uniqueYears, setUniqueYears] = useState<YearTypeValue[]>([]);
  const [uniqueSources, setUniqueSources] = useState<string[]>([]);
  const [isToggle, setIsToggle] = useState<boolean>(false);

  const getData = useCallback(async () => {
    dispatch(showSpinner());
    const plannedVolumeResponse = await fetchplannedVolume();
    const committedVolumeResponse = await fetchCommittedVolume();
    const kpiMovedDealsResponse = await fetchKpiMovedDeals();
    const plannedVolumeData = plannedVolumeResponse?.data;
    const committedVolumeData = committedVolumeResponse?.data;
    const kpiMovedDealsData = kpiMovedDealsResponse?.data;
    plannedVolumeData && setPlannedVolume(plannedVolumeData);
    committedVolumeResponse && setCommittedVolume(committedVolumeData);
    committedVolumeResponse && setKpiMovedDeals(kpiMovedDealsData);
    dispatch(setPlannedVolumeData(plannedVolumeData));
    dispatch(setCommittedVolumeData(committedVolumeData));
    dispatch(hideSpinner());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      transformedDataGroupData,
      uniqueYears: newUniqueYears,
      uniqueSources: newUniqueSources,
    } = plannedVsEstimatedSeriesData(plannedVolume, committedVolume, kpiMovedDeals, isToggle);
    const filteredTransformedDataGroupData = transformedDataGroupData.map((TransformedData) => ({
      ...TransformedData,
      data: TransformedData.data.filter(
        (item): item is number | null => typeof item === 'number' || item === null,
      ),
    }));
    setGroupedSeriesData(filteredTransformedDataGroupData);
    setUniqueYears(newUniqueYears);
    setUniqueSources(newUniqueSources);
  }, [plannedVolume, committedVolume, kpiMovedDeals, isToggle]);

  const categoriesData = uniqueYears.map((x: { year: number }) => x.year.toString());
  const plotBands = getPlotBands(uniqueYears);
  // Calculate the maximum value from the data
  const maxValue = Math.max(
    ...groupedSeriesData.flatMap((series) =>
      series.data.filter((item): item is number => typeof item === 'number'),
    ),
  );
  // Determine the tick interval based on the maximum value
  const tickInterval = maxValue > twoThousand ? fiveHundred : undefined;
  const options: Highcharts.Options = {
    chart: {
      type: 'area',
      height: fiveHundred,
      marginBottom: 100,
      style: {
        fontFamily: 'Shell Font, Arial, sans-serif', // Set 'Shell Font' as the primary font family for the whole chart
      },
    },
    title: {
      text: 'Contracted Forecast vs. Plan',
      align: 'left',
      y: 20,
    },
    subtitle: {
      align: 'left',
      text: `An overview of ${
        isToggle ? 'Sales Volume' : 'Net Absolute Emissions'
      } based on the latest estimate of<br /> contracted sales compared to the business plan forecast.`,
      style: {
        fontSize: '14px',
        fontFamily: ShellFont,
      },
    },
    legend: {
      itemStyle: {
        fontSize: '12px',
        fontWeight: 'light',
        color: '#757575',
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `${isToggle ? 'Volume (TW<sub>h</sub>)' : 'Carbon Emission (MTPA)'}`,
        style: CHART_AXIS_STYLE,
      },
      tickInterval,
    },
    xAxis: {
      categories: categoriesData,
      plotBands: plotBands as XAxisPlotBandsOptions[],
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: 2,
      formatter() {
        return toolTipFormatter(this as unknown as PointsData);
      },
    },
    plotOptions: {
      area: {
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: groupedSeriesData.map((data) => ({
      ...data,
      stacking: data.name === 'Plan' ? undefined : 'normal',
    })) as Highcharts.SeriesAreaOptions[],
  };
  return (
    <div data-testid='chart'>
      <StyledContainer>
        <ViewDetailsDiv>
          <ViewImportedDeals />
        </ViewDetailsDiv>
        <ToggleButtonDiv>
          <ToggleButton>
            <Toggle
              label='Emissions'
              mirrored
              data-testid='toggleButton-overallPortfolio'
              onChange={() => setIsToggle(!isToggle)}
            />
          </ToggleButton>
          <ToggleText>Volume</ToggleText>
        </ToggleButtonDiv>
        <Chart
          chartKey='LatestEstimateVsPlanned'
          highcharts={Highcharts}
          options={options}
          source={uniqueSources?.join(', ')}
          disclaimer={[CONTRACTED_VS_PLAN_DISCLAIMER]}
        />
      </StyledContainer>
    </div>
  );
};

export default LatestEstimateVsPlanned;
