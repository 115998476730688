import React, { useEffect, useContext, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { filterData as fetchFilterData } from '../../../../../api/services';
import { AppContext } from '../../../../../Context/AppContext';
import { SelectFormField } from '../../../../ReUsableComponents/SelectFormField/SelectFormField';
import { REGION_NAMES } from '../../../../../api/constants';
import {
  Commodity,
  Country,
  IAnalyticsSnapshotReportFilteredValues,
} from '../../../../../Types/IAnalyticsSnapshotReportType';

export const SnapshotReportFilterSection = () => {
  const { state: analyticsState, dispatch: snapshotDispatch } =
    useContext(AppContext).analyticsSnapshotReport;
  const filterData = analyticsState.analyticsSnapshotReportFilterOptions;
  // Define local state for each filter field
  const [yearSelected, setYearSelected] = useState<string[]>([]);
  const [quarterSelected, setQuarterSelected] = useState<string[]>([]);
  const [regionSelected, setRegionSelected] = useState<string[]>([]);
  const [countrySelected, setCountrySelected] = useState<string[]>([]);
  const [commoditySelected, setCommoditySelected] = useState('PW');
  const [unitNameSelected, setUnitNameSelected] = useState('TWH');
  const [transactionTypeSelected, setTransactionTypeSelected] = useState('NCF');
  const [countryOptions, setCountryOptions] = useState<{ label: string; value: string }[]>([]);
  const [reportStatusSelected, setReportStatusSelected] = useState<string[]>(['APPROVED']);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchFilterData();
      snapshotDispatch({
        type: 'setAnalyticsSnapshotReportFilterOptions',
        payload: response.data,
      });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!filterData) {
    return null; // or a loading spinner
  }

  const handleApplyFilters = () => {
    const payload: IAnalyticsSnapshotReportFilteredValues = {
      year: yearSelected,
      quarter: quarterSelected,
      region: regionSelected?.length ? regionSelected : Object.values(REGION_NAMES),
      countryCode: countrySelected,
      commodity: [commoditySelected],
      uom: unitNameSelected,
      transactionType: transactionTypeSelected,
      reportStatus: reportStatusSelected,
    };

    snapshotDispatch({
      type: 'setAnalyticsSnapshotReportFilteredValues',
      payload,
    });
  };

  const handleClearFilters = () => {
    // Reset local state
    setYearSelected([]);
    setQuarterSelected([]);
    setRegionSelected([]);
    setCountrySelected([]);
    setCommoditySelected('PW');
    setUnitNameSelected('TWH');
    setTransactionTypeSelected('NCF');
    setReportStatusSelected(['APPROVED']);

    // Create a payload with the initial state for each field
    const payload = {
      year: [],
      quarter: [],
      region: Object.values(REGION_NAMES),
      countryCode: [],
      commodity: ['PW'],
      uom: 'TWH',
      transactionType: transactionTypeSelected,
      reportStatus: ['APPROVED'],
    };

    // Dispatch the action to update the state in your context
    snapshotDispatch({
      type: 'setAnalyticsSnapshotReportFilteredValues',
      payload,
    });
  };

  const handleRegionChange = (e: string[]) => {
    setCountrySelected([]);
    setRegionSelected(e);
    let cntryptions: React.SetStateAction<
      {
        label: string;
        value: string;
      }[]
    > = [];
    if (Array.isArray(e)) {
      cntryptions = [
        ...new Set(
          e.flatMap((region) =>
            filterData.regionCountryMapping[region]?.map((el: Country) => {
              return {
                label: el.COUNTRY_NAME,
                value: el.COUNTRY_CODE,
              };
            }),
          ),
        ),
      ];
    }
    setCountryOptions(cntryptions);
  };

  return (
    <div data-testid='snapshot-report-filter' style={{ padding: '20px' }}>
      <Flexbox flexDirection='column'>
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            lineHeight: '26px',
            color: '#404040',
            padding: '10px 0',
            marginTop: '26px',
            width: '251px',
          }}
        >
          Filters
        </div>
        <SelectFormField
          id='year'
          label='Year'
          testId='Year'
          value={yearSelected}
          onChange={setYearSelected}
          options={filterData.year.map((el: number) => el.toString())}
          mode='multiple'
        />

        <SelectFormField
          id='quarter'
          label='Quarter'
          testId='Quarter'
          value={quarterSelected}
          onChange={setQuarterSelected}
          options={filterData.quarter}
          mode='multiple'
        />

        <SelectFormField
          id='region'
          label='Region'
          testId='Region'
          value={regionSelected}
          onChange={(e: string[]) => handleRegionChange(e)}
          options={filterData.region}
          mode={'multiple'}
        />
        <SelectFormField
          id='country'
          label='Country'
          testId='Country'
          value={countrySelected}
          onChange={setCountrySelected}
          options={countryOptions}
          mode='multiple'
        />
        <SelectFormField
          id='commodity'
          label='Commodity'
          testId='Commodity'
          value={commoditySelected}
          onChange={setCommoditySelected}
          mode={'single'}
          options={filterData.commodity.map((el: Commodity) => {
            return {
              label: el.TRADE_NAME,
              value: el.TRADE_CODE,
            };
          })}
        />
        <SelectFormField
          id='transactionType'
          label='Transaction Type'
          testId='Transaction Type'
          value={transactionTypeSelected}
          onChange={setTransactionTypeSelected}
          options={filterData.transactionType}
        />
        <SelectFormField
          id='unitName'
          label='Unit Name'
          testId='Unit Name'
          value={unitNameSelected}
          onChange={setUnitNameSelected}
          options={filterData.unitName}
        />
        <SelectFormField
          id='reportStatus'
          label='Report Status'
          testId='Report Status'
          value={reportStatusSelected}
          onChange={setReportStatusSelected}
          options={filterData.reportStatus}
        />
        <Button className='getDataBtn' data-testid='Apply Filters' onClick={handleApplyFilters}>
          Apply Filters
        </Button>
        <Button
          className='getDataBtn'
          variant='outlined'
          style={{ marginTop: '10px' }}
          data-testid='clear-filters'
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Flexbox>
      <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.06)' }}></div>
    </div>
  );
};
