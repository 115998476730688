/* eslint-disable no-unused-vars */
import {
  Alert,
  Alignments,
  Flexbox,
  FormField,
  Loading,
  Modal,
  ModalAction,
  Select,
  Sentiments,
  Sizes,
  Spacings,
  Variants,
} from '@sede-x/shell-ds-react-framework';
import React, { useContext, useState, useEffect, SetStateAction, Dispatch } from 'react';
import { generateNcfSnapshotName } from '../../../api/services';
import { AppContext } from '../../../Context/AppContext';
import '../../style.module.css';
import {
  SUBMITTED_FOR_APPROVAL,
  COMMODITY_POWER_SHORT_NAME,
  ASIA,
  DATA_SNAPSHOT_REGIONS,
  FOUR_HUNDRED,
  AUSTRALIA,
  COMMODITY_NG_SHORT_NAME,
} from '../../../api/constants';
import { getDefaultOptions } from '../../Utils/utils';
import {
  commodityOptions,
  defaultRegionOptions,
  getTimePeriodOptions,
  halfYearOption,
  handleRegionChange,
  handleTimePeriodChange,
  monthOption,
  quarterOption,
  resetModal,
  yearlyOption,
} from './SnapshotFunctions';
import { Action, ICustomError, ISnapshotData } from '../../../Types/types';

export const AddNewSnapshotModal = (props: {
  isAddSnapshot: boolean;
  setIsAddSnapshot: Dispatch<SetStateAction<boolean>>;
  updateSnapshotData: (
    pageNumber: number,
    dispatch: Dispatch<Action>,
    setStateVars: {
      setCurrentPaginationNumber: Dispatch<SetStateAction<number>>;
      userRegionShortName: string;
      setPublishPaginationData: Dispatch<SetStateAction<ISnapshotData[]>>;
      setTotalCount: Dispatch<SetStateAction<number>>;
      setCheckedID: Dispatch<SetStateAction<number>>;
      setCols: Dispatch<SetStateAction<string[]>>;
      setIsServiceError: Dispatch<SetStateAction<boolean>>;
    },
  ) => void;
  setStateVars: {
    setCurrentPaginationNumber: Dispatch<SetStateAction<number>>;
    userRegionShortName: string;
    setPublishPaginationData: Dispatch<SetStateAction<ISnapshotData[]>>;
    setTotalCount: Dispatch<SetStateAction<number>>;
    setCheckedID: Dispatch<SetStateAction<number>>;
    setCols: Dispatch<SetStateAction<string[]>>;
    setIsServiceError: Dispatch<SetStateAction<boolean>>;
  };
}) => {
  const { dispatch } = useContext(AppContext).appData;

  const [showSnapshotNameGenerateMessage, setShowSnapshotNameGenerateMessage] = useState(false);

  const [region, setRegion] = useState('');
  const [commodity, setCommodity] = useState('');
  const [timeYear, setTimeYear] = useState('Select');
  const [unitOfTime, setUnitOfTime] = useState('Select');
  const [timePeriod, setTimePeriod] = useState('Select');
  const [isRegionAsiaJapan, setIsRegionAsiaJapan] = useState(false);
  const [isRegionAustralia, setIsRegionAustralia] = useState(false);
  const [unitOfTimeOptions, setUnitOfTimeOptions] = useState([
    { value: 'Select', label: 'Select' },
  ]);
  const [timePeriodOptions, setTimePeriodOptions] = useState([
    { value: 'Select', label: 'Select' },
  ]);

  const [isRegionInvalid, setIsRegionInvalid] = useState(false);
  const [isCommodityInvalid, setIsCommodityInvalid] = useState(false);
  const [isTimeYearInvalid, setIsTimeYearInvalid] = useState(false);
  const [isUnitOfTimeInvalid, setIsUnitOfTimeInvalid] = useState(false);
  const [isTimePeriodInvalid, setIsTimePeriodInvalid] = useState(false);

  const [generatedSnapshotNameMessage, setGeneratedSnapshotNameMessage] = useState('');
  const [isGenerateSnapshotNameSuccess, setIsGenerateSnapshotNameSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isPrevSnapshotApproved, setIsPrevSnapshotApproved] = useState(true);
  const [prevTimePeriod, setPrevTimePeriod] = useState('');
  const [updatedRegionOptions, setUpdatedRegionOptions] = useState<
    { value: string; label: string }[]
  >(getDefaultOptions());
  const [isCommodityDisabled, setIsCommodityDisabled] = useState(false);
  const { state } = useContext(AppContext).appData;

  useEffect(() => {
    if (props.isAddSnapshot) {
      // Filter the array to find a match for userRegion. This is specific to users with particular region
      const filteredOptions = defaultRegionOptions.filter((option) => {
        const labelUpperCase = option.label.toUpperCase();
        return (
          state.userRegions.includes(labelUpperCase) ||
          (labelUpperCase.includes('-') && state.userRegions.includes(labelUpperCase.split('-')[0]))
        );
      });
      // If filteredOptions is empty (no match found), return the entire regionOptions array
      const updatedRegions = filteredOptions.length > 0 ? filteredOptions : defaultRegionOptions;
      setUpdatedRegionOptions(updatedRegions);
      if (updatedRegions.length === 1) {
        setIsCommodityDisabled(true);
        setRegion(updatedRegions[0].value);
        setIsRegionInvalid(false);
      }
      if (state.userRegions.includes(ASIA)) {
        setIsRegionAsiaJapan(true);
        setCommodity(COMMODITY_POWER_SHORT_NAME);
      }
      if (state.userRegions.includes(AUSTRALIA)) {
        setIsRegionAustralia(true);
        setCommodity(COMMODITY_NG_SHORT_NAME);
      }
    }
  }, [props.isAddSnapshot]);

  const innerOnClose = () => {
    const setOptions = {
      setRegion,
      setTimeYear,
      setUnitOfTime,
      setTimePeriod,
      setCommodity,
      setShowSnapshotNameGenerateMessage,
      setIsGenerateSnapshotNameSuccess,
      setIsPrevSnapshotApproved,
      setPrevTimePeriod,
      setIsRegionInvalid,
      setIsTimeYearInvalid,
      setIsTimePeriodInvalid,
      setIsUnitOfTimeInvalid,
      setIsCommodityInvalid,
    };
    resetModal(setOptions);
    props.setIsAddSnapshot(false);
  };

  const getRegionShortName = () => {
    const isRegionHasOneOption = defaultRegionOptions.length === 1;
    const currentRegion = isRegionHasOneOption ? defaultRegionOptions[0].value : region;
    let DataSnapshotRegion;
    if (currentRegion) {
      const requestedRegionName = currentRegion.toUpperCase();
      DataSnapshotRegion =
        DATA_SNAPSHOT_REGIONS[requestedRegionName as keyof typeof DATA_SNAPSHOT_REGIONS];
    }
    return DataSnapshotRegion;
  };

  const generateSnapshotName = async () => {
    setGeneratedSnapshotNameMessage('');
    const isRegionValid = defaultRegionOptions.length === 1 ? true : region !== '';
    const isCommodityValid = commodity !== '';
    const isTimeYearValid = timeYear !== 'Select';
    const isUnitOfTimeValid = unitOfTime !== 'Select';
    const isTimePeriodValid = timePeriod !== 'Select';

    const regionVal = getRegionShortName();

    isRegionValid ? setIsRegionInvalid(false) : setIsRegionInvalid(true);
    isCommodityValid ? setIsCommodityInvalid(false) : setIsCommodityInvalid(true);
    isTimeYearValid ? setIsTimeYearInvalid(false) : setIsTimeYearInvalid(true);
    isUnitOfTimeValid ? setIsUnitOfTimeInvalid(false) : setIsUnitOfTimeInvalid(true);
    isTimePeriodValid ? setIsTimePeriodInvalid(false) : setIsTimePeriodInvalid(true);

    const isAllFieldsValid = [
      isRegionValid,
      isCommodityValid,
      isUnitOfTimeValid,
      isTimePeriodValid,
    ].every((field) => field);
    if (isAllFieldsValid) {
      setIsLoading(true);
      const options = {
        region: regionVal,
        commodity,
        year: timeYear,
        timePeriod: timePeriod.toUpperCase(),
        unitOfTime: unitOfTime.toUpperCase(),
      };
      try {
        const generateSnapshotResponse = await generateNcfSnapshotName(options);

        const snapshotNameGenerated = generateSnapshotResponse.data.snapshotName;
        const isLastSnapshotApproved = !generateSnapshotResponse.data.isPreviousSnapshotNotApproved;
        setGeneratedSnapshotNameMessage(
          `Snapshot name '${snapshotNameGenerated}' has been generated successfully.`,
        );
        setIsGenerateSnapshotNameSuccess(true);
        setIsPrevSnapshotApproved(isLastSnapshotApproved);
      } catch (e: unknown) {
        const error = e as ICustomError;
        if (error.response?.status === FOUR_HUNDRED) {
          setGeneratedSnapshotNameMessage(error.response.data.errMessage);
        } else {
          setGeneratedSnapshotNameMessage('Server Error !! Please try again.');
        }
        setIsGenerateSnapshotNameSuccess(false);
      }
      setShowSnapshotNameGenerateMessage(true);
      setIsLoading(false);
    }
  };

  const submitForApproval = async () => {
    const regionFullName =
      defaultRegionOptions.length === 1 ? defaultRegionOptions[0].value : region;
    const userRegionShortName =
      DATA_SNAPSHOT_REGIONS[regionFullName.toUpperCase() as keyof typeof DATA_SNAPSHOT_REGIONS] ||
      '';

    const options = {
      region: userRegionShortName,
      commodity,
      year: timeYear,
      timePeriod: timePeriod.toUpperCase(),
      unitOfTime: unitOfTime.toUpperCase(),
      snapshotStatus: SUBMITTED_FOR_APPROVAL,
    };
    try {
      await generateNcfSnapshotName(options);
      const setOptions = {
        setRegion,
        setTimeYear,
        setUnitOfTime,
        setTimePeriod,
        setCommodity,
        setShowSnapshotNameGenerateMessage,
        setIsGenerateSnapshotNameSuccess,
        setIsPrevSnapshotApproved,
        setPrevTimePeriod,
        setIsRegionInvalid,
        setIsTimeYearInvalid,
        setIsTimePeriodInvalid,
        setIsUnitOfTimeInvalid,
        setIsCommodityInvalid,
      };
      resetModal(setOptions);
      props.updateSnapshotData(1, dispatch, props.setStateVars);
    } catch (e) {
      setGeneratedSnapshotNameMessage(`We are facing some issues. Please try again.`);
    }

    props.setIsAddSnapshot(false);
  };

  const timeYearOptionsFunc = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2022;
    const differenceInYear = currentYear - startYear;
    const timeYrOptions = [];
    for (let i = 0; i <= differenceInYear; i++) {
      timeYrOptions.push({
        value: (currentYear - i).toString(),
        label: (currentYear - i).toString(),
      });
    }
    const currentMonth = new Date().getMonth() + 1;
    // after all years are set, if current month is January then remove the latest year. coz options must be a completed time line and January is still running for current year.
    if (currentMonth === 1) {
      timeYrOptions.shift();
    }
    return timeYrOptions;
  };

  const handleTimeYearChange = (e: string) => {
    setTimeYear(e);
    setUnitOfTime('Select');
    setTimePeriod('Select');
    setIsTimeYearInvalid(false);
    setIsPrevSnapshotApproved(true);
    setShowSnapshotNameGenerateMessage(false);

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Month starts with index 0, hence addition of 1 to get current month

    const unitOfTimeOpts = getUnitOfTimeOptions(Number(e), currentYear, currentMonth);

    setUnitOfTimeOptions(unitOfTimeOpts);
  };

  const getUnitOfTimeOptions = (year: number, currentYear: number, currentMonth: number) => {
    const unitOfTimeOpts = [];
    const monthCountForQuarter = 3;
    const monthCountForHalfYear = 6;

    if (year < currentYear) {
      unitOfTimeOpts.push(yearlyOption);
      unitOfTimeOpts.push(halfYearOption);
      unitOfTimeOpts.push(quarterOption);
      unitOfTimeOpts.push(monthOption);
    } //if year chosen is current year with current month greater than 6
    else if (year === currentYear) {
      if (currentMonth > monthCountForHalfYear) {
        unitOfTimeOpts.push(halfYearOption);
        unitOfTimeOpts.push(quarterOption);
        unitOfTimeOpts.push(monthOption);
      } //if year chosen is current year with current month greater than 3
      else if (currentMonth > monthCountForQuarter) {
        unitOfTimeOpts.push(quarterOption);
        unitOfTimeOpts.push(monthOption);
      } //if year chosen is current year with current month less than 3
      else {
        unitOfTimeOpts.push(monthOption);
      }
    }

    return unitOfTimeOpts;
  };

  const hanldeUnitOfTimeChange = (e: string) => {
    setUnitOfTime(e);
    setTimePeriod('Select');
    setIsUnitOfTimeInvalid(false);
    setIsPrevSnapshotApproved(true);
    setShowSnapshotNameGenerateMessage(false);
    const timePeriodOpts = getTimePeriodOptions(e, timeYear);
    setTimePeriodOptions(timePeriodOpts);
  };

  // For temporary implementation as glasshub not taking 'showActionButton' property.
  const actionsArray: ModalAction[] = [
    {
      label: 'Generate Snapshot Name',
      action: () => {
        generateSnapshotName();
      },
      props: {
        variant: Variants.Outlined,
      },
    },
  ];
  if (isGenerateSnapshotNameSuccess && showSnapshotNameGenerateMessage) {
    actionsArray.push({
      label: 'Submit for Approval',
      action: () => {
        submitForApproval();
        innerOnClose();
      },
    });
  }
  return (
    <Modal
      title='Create Snapshot'
      description='The available options(Year, Unit of Time and Time Period) are for a completed time line only.'
      open={props.isAddSnapshot}
      onClose={() => {
        innerOnClose();
      }}
      actionsAlignment={Alignments.Center}
      actions={actionsArray}
      size={Sizes.Large}
      padding={Spacings.Generous}
      data-testid='addSnapshotModal'
    >
      <Flexbox gap='24px 24px' justifyContent='center'>
        <FormField id='year' label='Year' mandatory={true}>
          <Select
            size='small'
            allowClear={false}
            value={timeYear}
            options={timeYearOptionsFunc()}
            onChange={(e: string) => handleTimeYearChange(e)}
            invalid={isTimeYearInvalid}
            data-testid='year'
          />
        </FormField>
        <FormField id='unitOfTime' label='Unit of Time' mandatory={true}>
          <Select
            size='small'
            value={unitOfTime}
            allowClear={false}
            options={unitOfTimeOptions}
            disabled={timeYear === 'Select' || timeYear === ''}
            onChange={(e: string) => hanldeUnitOfTimeChange(e)}
            invalid={isUnitOfTimeInvalid}
            data-testid='unitOfTime'
          />
        </FormField>
        <FormField id='timePeriod' label='Time Period' mandatory={true}>
          <Select
            size='small'
            data-testid='timePeriod'
            value={timePeriod}
            allowClear={false}
            options={timePeriodOptions}
            disabled={unitOfTime === '' || unitOfTime === 'Select'}
            onChange={(e: string) => {
              const setOptions = {
                setPrevTimePeriod,
                setIsPrevSnapshotApproved,
                setShowSnapshotNameGenerateMessage,
                setTimePeriod,
                setIsTimePeriodInvalid,
              };
              handleTimePeriodChange(e, unitOfTime, timeYear, setOptions);
            }}
            invalid={isTimePeriodInvalid}
          />
        </FormField>
      </Flexbox>
      <Flexbox gap='24px 24px' justifyContent='center'>
        <FormField id='region' label='Region' mandatory={true}>
          <Select
            size='small'
            data-testid='region'
            allowClear={false}
            value={region}
            options={updatedRegionOptions}
            disabled={isCommodityDisabled}
            onChange={(e: string) => {
              const setOptions = {
                setRegion,
                setIsRegionInvalid,
                setCommodity,
                setIsRegionAsiaJapan,
                setShowSnapshotNameGenerateMessage,
              };
              handleRegionChange(e, setOptions, setIsRegionAustralia);
            }}
            invalid={isRegionInvalid}
          />
        </FormField>

        <FormField id='commodity' label='Commodity' mandatory={true}>
          <Select
            size='small'
            data-testid='commodity'
            allowClear={false}
            value={commodity}
            id='commodityId'
            options={commodityOptions}
            disabled={isRegionAsiaJapan || isRegionAustralia}
            onChange={(e: React.SetStateAction<string>) => {
              setCommodity(e);
              setIsCommodityInvalid(false);
              setShowSnapshotNameGenerateMessage(false);
            }}
            defaultValue={commodityOptions[0].value}
            invalid={isCommodityInvalid}
          />
        </FormField>
      </Flexbox>
      <Flexbox justifyContent='center'>
        {isLoading ? (
          <Loading />
        ) : (
          showSnapshotNameGenerateMessage && (
            <p
              data-testid='snapshotSuccessMessage'
              style={isGenerateSnapshotNameSuccess ? { color: 'green' } : { color: 'red' }}
              className='publishSnapshotGenerateMessage'
            >
              {generatedSnapshotNameMessage}
            </p>
          )
        )}
      </Flexbox>
      {!isPrevSnapshotApproved && (
        <Alert sentiment={Sentiments.Warning}>
          You are trying to create a new snapshot for {timePeriod} with previous snapshot for{' '}
          {prevTimePeriod} still not approved. Please make sure to get {prevTimePeriod} snapshot
          approved.
        </Alert>
      )}
    </Modal>
  );
};
