import styled from 'styled-components';

export const ToggleButtonDiv = styled.div`
  position: absolute;
  top: 75px; /* Adjust the top position as needed */
  right: 28px; /* Adjust the right position as needed */
  background-color: white; /* Optional background color */
  display: flex;
  align-items: center;
  z-index: 2; /* Increase the z-index value as needed */
`;

export const ViewDetailsDiv = styled.div`
  position: absolute;
  top: -1px;
  right: 50px;
  background-color: white; /* Optional background color */
  display: flex;
  align-items: center;
  z-index: 1; /* Increase the z-index value as needed */
`;

export const ToggleText = styled.div`
  color: rgb(64, 64, 64);
  margin-left: unset;
  margin-right: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const ToggleButton = styled.div`
  margin-right: 10px; /* Add spacing between button and text */
`;

export const StyledDiv = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ChartContainer = styled.div`
  flex-grow: 1;
`;

export const FooterNotesRenDiv = styled.div`
  padding-left: 16px;
  padding-top: 5px;
  font-style: italic;
  color: #757575;
  font-size: 12px;
  margin-top: -7px;
  background: #ffffff;
`;
