import { axiosInstanceCPM } from '../../commonUtils/apiAxiosInstance/axiosInstance';
import { ICarbonStatus } from '../Types/types';
const API_VERSION_1 = 'api/v1/';
const fetchSauApi = `${API_VERSION_1}sau`;
const fetchEvpApi = `${API_VERSION_1}evp/?`;
const fetchIpuApi = `${API_VERSION_1}ipu/?`;
const fetchVpApi = `${API_VERSION_1}vp/?`;
const fetchBusiness = `${API_VERSION_1}business/?`;
const fetchCarbonRagStatus = `${API_VERSION_1}carbonemissionragstatus`;
const _fetchMCRStatus = `${API_VERSION_1}margintocarbonratiostatus`;
const _fetchCommodityOverview = `${API_VERSION_1}commodityoverview`;
const _fetchCarbonemissionDeltaView = `${API_VERSION_1}carbonemissiondeltaview`;
const countryLevelAverageMCR = `${API_VERSION_1}countrylevelaveragemcr`;
const plannedVolume = `${API_VERSION_1}plannedvolume`;
const committedVolume = `${API_VERSION_1}committedvolume`;
const _fetchcenariomodelingkpisurl = `${API_VERSION_1}scenario-modeling-kpis`;
const fetchOverallportfolio = `${API_VERSION_1}overallportfolio/`;
const _fetchCarbonBudgetLine = `${API_VERSION_1}carbonbudgetline`;
const _fetchMarginToCarbonRatio = `${API_VERSION_1}margintocarbonratio/`;
const _fetchAvgmMarginToCarbonRatio = `${API_VERSION_1}avgmargintocarbonratio/`;
const fetchOverallCarbonStatus = `${API_VERSION_1}overallcarbonstatus/`;
const saveOverAllCarbonStatus = `${API_VERSION_1}saveoverallcarbonstatus/`;
const _fatchCarbonIntensityTarget = `${API_VERSION_1}carbonintensitytarget/`;
const fetchBaseCaseOverAllPortfolio = `${API_VERSION_1}basecase-overall-portfolio`;
const getKpiMovedDeals = `${API_VERSION_1}kpi-moved-deals`;
const netCarbonIntensity = `${API_VERSION_1}netcarbonintensity`;
const importedDeals = `${API_VERSION_1}imported-deals`;

//API used in UC1 Scenario modelling
export const overAllPortfolio = () => {
  return axiosInstanceCPM.get(fetchOverallportfolio);
};

export const fetchCarbonBudgetLine = () => {
  return axiosInstanceCPM.get(_fetchCarbonBudgetLine);
};

export const fetchMarginToCarbonRatio = () => {
  return axiosInstanceCPM.get(_fetchMarginToCarbonRatio);
};

export const fetchAvgMarginToCarbonRatio = () => {
  return axiosInstanceCPM.get(_fetchAvgmMarginToCarbonRatio);
};

export const fetchIPUFilterData = (url: string) => {
  return axiosInstanceCPM.get(url);
};

export const fetchevpFiltered = (url: string) => {
  return axiosInstanceCPM.get(url);
};

export const fetchSeIpuRegionFiltered = (url: string) => {
  return axiosInstanceCPM.get(url);
};

export const fetchVPFilterData = (url: string) => {
  return axiosInstanceCPM.get(url);
};

export const fetchBusinessFilterData = (url: string) => {
  return axiosInstanceCPM.get(url);
};

export const fetchSauFilterData = () => {
  const url = `${fetchSauApi}`;
  return axiosInstanceCPM.get(url);
};

export const fetchEVP = (region: string) => {
  const url = `${fetchEvpApi}region=${region}`;
  return axiosInstanceCPM.get(url);
};

export const fetchIPU = (region: string, evp: string) => {
  const url = `${fetchIpuApi}region=${region}&evp=` + encodeURIComponent(evp);
  return axiosInstanceCPM.get(url);
};

export const fetchVP = (region: string, evp: string, ipu: string) => {
  const url = `${fetchVpApi}region=${region}&evp=
  ${encodeURIComponent(evp)}&ipu=${encodeURIComponent(ipu)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchBUsiness = (region: string, evp: string, ipu: string, vp: string) => {
  const url = `${fetchBusiness}region=${region}&evp=
  ${encodeURIComponent(evp)}&ipu=${encodeURIComponent(ipu)}&vp=${encodeURIComponent(vp)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchRagStatus = () => {
  return axiosInstanceCPM.get(fetchCarbonRagStatus);
};

export const fetchMCRStatus = () => {
  return axiosInstanceCPM.get(_fetchMCRStatus);
};

export const fetchCommodityOverview = () => {
  return axiosInstanceCPM.get(_fetchCommodityOverview);
};

export const fetchCarbonemissionDeltaView = () => {
  return axiosInstanceCPM.get(_fetchCarbonemissionDeltaView);
};

export const overallCarbonStatus = () => {
  return axiosInstanceCPM.get(fetchOverallCarbonStatus);
};

export const saveCarbonStatus = (options: ICarbonStatus) => {
  return axiosInstanceCPM.post(saveOverAllCarbonStatus, options);
};

export const fetchEVPCommodityOverview = (sau: string) => {
  const url = `${_fetchCommodityOverview}?sau=${encodeURIComponent(sau)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchSeIPURegionCommodityOverview = (sau: string, evp: string) => {
  const url = `${_fetchCommodityOverview}?sau=${encodeURIComponent(sau)}
  &evp=${encodeURIComponent(evp)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchIPUCommodityOverview = (sau: string, evp: string, seIpuRegion: string) => {
  const url = `${_fetchCommodityOverview}?sau=${encodeURIComponent(sau)}
  &evp=${encodeURIComponent(evp)}&seIpuRegion=${encodeURIComponent(seIpuRegion)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchVPCommodityOverview = (
  sau: string,
  evp: string,
  seIpuRegion: string,
  ipu: string,
) => {
  const url = `${_fetchCommodityOverview}?sau=${encodeURIComponent(sau)}
  &evp=${encodeURIComponent(evp)}&seIpuRegion=${encodeURIComponent(seIpuRegion)}
  &ipu=${encodeURIComponent(ipu)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchBusinessCommodityOverview = (
  sau: string,
  evp: string,
  seIpuRegion: string,
  ipu: string,
  vp: string,
) => {
  const url = `${_fetchCommodityOverview}?sau=${encodeURIComponent(sau)}
  &evp=${encodeURIComponent(evp)}&seIpuRegion=${encodeURIComponent(seIpuRegion)}
  &ipu=${encodeURIComponent(ipu)}&vp=${encodeURIComponent(vp)}`;
  return axiosInstanceCPM.get(url);
};

export const fetchCarbonIntensityTarget = () => {
  return axiosInstanceCPM.get(_fatchCarbonIntensityTarget);
};

export const fetchCountryLevelAverageMCR = () => {
  return axiosInstanceCPM.get(countryLevelAverageMCR);
};

export const fetchplannedVolume = () => {
  return axiosInstanceCPM.get(plannedVolume);
};
export const fetchCommittedVolume = () => {
  return axiosInstanceCPM.get(committedVolume);
};
export const fetchbasecaseOverallPortfolio = () => {
  return axiosInstanceCPM.get(fetchBaseCaseOverAllPortfolio);
};

export const fetchbasecasetabledata = (regions: string[]) => {
  const regionParams = regions.map((region) => `regions=${region}`).join('&');
  const url = `${_fetchcenariomodelingkpisurl}?${regionParams}`;
  return axiosInstanceCPM.get(url);
};
export const fetchKpiMovedDeals = () => {
  return axiosInstanceCPM.get(getKpiMovedDeals);
};
export const fetchNetCarbonIntensity = () => {
  return axiosInstanceCPM.get(netCarbonIntensity);
};

export const getImportedDeals = () => {
  return axiosInstanceCPM.get(importedDeals);
};
