import Highcharts from 'highcharts';
import {
  CHART_AXIS_STYLE,
  TITLE_FONT_FAMILY,
  pipeLineGas,
  point5,
  ten,
  two,
} from '../../../constants';
import {
  ChartAxisStyle,
  ChartLegendStyle,
  getColorByIndex,
  getSubTitle,
  toolTipFormatter,
} from '../../../utils';
import { SummedValues } from '../CarbonIntensityChart/types';
import { YearProcessedDataItem } from './types';
import { PointsData } from '../../../Types/types';
interface HighchartOptionsProps {
  commodityVal: string;
  charTitleType: string;
  chartSubtitleType: string;
  chartSubtitleKPI: string;
  resultYearArray: YearProcessedDataItem[];
  indexOfLastActuals: number;
  chartType: string;
  processedCombinedArrayWithKey: SummedValues[];
}
export const highchartOptions = (highchartOptionsData: HighchartOptionsProps) => {
  const column = 'column';
  const actuals = 'actuals';
  const sales = 'sales';
  const left = 'left';
  const bottom = 'bottom';
  const white = 'white';
  const plan = 'Plan';
  const {
    commodityVal,
    charTitleType,
    resultYearArray,
    indexOfLastActuals,
    chartType,
    processedCombinedArrayWithKey,
  } = highchartOptionsData;
  const title = commodityVal === pipeLineGas ? 'Gas' : commodityVal;
  const options: Highcharts.Options = {
    chart: {
      type: column,
      height: 500,
      marginBottom: 90,
    },
    title: {
      text: `${title} ${charTitleType} by Source`,
      align: left,
      x: 16,
      y: 20,
      style: {
        fontSize: '20px',
        fontFamily: TITLE_FONT_FAMILY,
      },
    },
    subtitle: {
      text: getSubTitle(commodityVal, chartType, title),
      align: left,
      x: 16,
      style: {
        fontSize: '14px',
        fontFamily: TITLE_FONT_FAMILY,
        margin: '20px',
        floating: false,
      },
    },
    xAxis: {
      categories: resultYearArray.map((x: YearProcessedDataItem) => x.year),
      plotBands:
        indexOfLastActuals !== -1
          ? [
              {
                color: 'rgba(128, 128, 128, 0.3)',
                from: -point5,
                to: resultYearArray.length - 1 - indexOfLastActuals + point5,
                label: {
                  text: 'Actuals',
                  style: ChartAxisStyle,
                  verticalAlign: bottom,
                  y: 40,
                },
              },

              {
                color: white,
                from: resultYearArray.length - indexOfLastActuals - point5,
                to: resultYearArray.length - 1 + point5,
                label: {
                  text: plan,
                  style: ChartAxisStyle,
                  verticalAlign: bottom,
                  y: 40,
                },
              },
            ]
          : [
              {
                color: white,
                from: -point5,
                to: resultYearArray.length - 1 + point5,
                label: {
                  text: plan,
                  style: ChartAxisStyle,
                  verticalAlign: bottom,
                  y: 40,
                },
              },
            ],
    },
    yAxis: {
      title: {
        useHTML: true,
        style: CHART_AXIS_STYLE,
        text: chartType === sales ? 'Volume (TWh)' : 'Carbon Emission (MTPA)',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        grouping: false,
        pointWidth: 22,
      },
    },
    legend: {
      x: 16,
      itemStyle: ChartLegendStyle,
      layout: 'horizontal',
      align: left,
      verticalAlign: 'top',
      reversed: true,
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: two,
      formatter() {
        return toolTipFormatter(this as unknown as PointsData);
      },
    },
    series:
      processedCombinedArrayWithKey && processedCombinedArrayWithKey.length > 0
        ? processedCombinedArrayWithKey.map((dataObj: SummedValues, index: number) => ({
            type: column,
            name: dataObj.name,
            data:
              dataObj?.data instanceof Array
                ? dataObj.data.map((dataPoint: { value: number; type: string }) => ({
                    y: dataPoint.value ?? 0,
                    tooltipText: `${dataPoint.type === actuals ? '(Actual)' : ''}`, // Use the custom tooltip text
                  }))
                : [],
            color: getColorByIndex(index, commodityVal),
            dataLabels: {
              enabled: false,
              style: {
                fontSize: '12px',
              },
              verticalAlign: bottom,
              y: -ten,
            },
          }))
        : [
            {
              type: column, // Add the series type here
              name: '',
              // data: dataObj.data.map((item) => item.value),
              data: [],
              dataLabels: {
                enabled: false,
                style: {
                  fontSize: '12px',
                },
                verticalAlign: bottom,
                y: -10,
              },
            },
          ],
  };
  return options;
};
