import React, { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import {
  EQUITY_GAS_FIRED_POWER_GENERATION,
  EQUITY_RENEWABLE_POWER_GENERATION,
  EXTERNAL_GAS_SALES_VOLUMES_PIPELINE,
  EXTERNAL_GAS_SALES_VOLUMES_RENEWABLE,
  EXTERNAL_POWER_SALES_GF,
  EXTERNAL_POWER_SALES_RENEWABLE_CPPA,
  EXTERNAL_POWER_SALES_RENEWABLE_DF,
  EXTERNAL_POWER_SALES_RENEWABLE_REC,
  EXTERNAL_POWER_SALES_RESIDUAL_GRID,
  INLAND_SALES_VOLUME_NATURAL_GAS,
  NONRENEWABLE,
  PARIG_FINANCE_REPORTING,
  PLANNING_ONE,
  POWER_INTENSITY_MODEL,
  POWER_INTENSITY_MODEL_RES,
  RENEWABLE,
  RENEWABLE_ATTRIBUTES_REC,
  REPORTONE,
  RESIDUAL_GRID,
  TANSO,
  THREE_P_GAS_FIRED_POWER_SUPPLY_PPA,
  THREE_P_POWER_SUPPLY_MARKET_POOL,
  THREE_P_RENEWABLE_POWER_SUPPLY_PPA,
} from '../../../api/constants';
import {
  StackedVerticalBarChartProps,
  TradeData,
  ProcessedDataItem,
  KeyValueData,
  YearProcessedDataItem,
} from './types';
import Chart from '../../Chart/Chart';
import { getColorByIndex } from '../../../utils';
import { highchartOption } from './highchartOption';
import { currentYear } from '../../../utils/utils';
import { getRename } from '../../CommodityOverview/Utils';

const actuals = 'actuals';
const power = 'Power';
const number = 'number';
const naturalGas = 'Natural Gas';
const CountryBreakdownChart: React.FC<StackedVerticalBarChartProps> = ({
  commodityFilteredValue,
  commodityVal,
}) => {
  const KpiForNonRen = new Set([
    THREE_P_GAS_FIRED_POWER_SUPPLY_PPA,
    EQUITY_GAS_FIRED_POWER_GENERATION,
    EXTERNAL_POWER_SALES_GF,
    EXTERNAL_POWER_SALES_RENEWABLE_DF,
  ]);
  const KpiForResGrid = new Set([
    THREE_P_POWER_SUPPLY_MARKET_POOL,
    EXTERNAL_POWER_SALES_RESIDUAL_GRID,
  ]);
  const KpiForRenewable = new Set([
    THREE_P_RENEWABLE_POWER_SUPPLY_PPA,
    RENEWABLE_ATTRIBUTES_REC,
    EQUITY_RENEWABLE_POWER_GENERATION,
    EXTERNAL_POWER_SALES_RENEWABLE_CPPA,
    EXTERNAL_POWER_SALES_RENEWABLE_REC,
  ]);

  const historicalSources = new Set([
    TANSO,
    REPORTONE,
    POWER_INTENSITY_MODEL,
    POWER_INTENSITY_MODEL_RES,
  ]);
  const planningSources = new Set([PLANNING_ONE, PARIG_FINANCE_REPORTING]);
  const commodityNewFilteredValue = commodityFilteredValue;

  const commodityNewFilteredValueCurrentYear = commodityNewFilteredValue.filter(
    (x) => x.TRADE_YEAR === currentYear,
  );

  // Step 1: Find unique sources other than Tanso as per business analyst
  const uniqueSources = [
    ...new Set(
      commodityNewFilteredValueCurrentYear
        .map((item) => item.SOURCE)
        .filter((x) => x === PLANNING_ONE || x === PARIG_FINANCE_REPORTING),
    ),
  ];

  // Step 2: Concatenate unique sources into a string
  const concatenatedSources = uniqueSources.join(', ');
  function getTotalQuantityByCountry(
    data: TradeData[],
    _sourcesHist: Set<string>,
    sourcesPlan: Set<string>,
    kpiSet: Set<string>,
  ): Map<string, number> {
    const countryYearMap = new Map<string, number>();

    data.forEach((item) => {
      if (sourcesPlan.has(item.SOURCE) && kpiSet.has(item.KPI)) {
        const countryKey = `${item.COUNTRY}-country`;
        if (countryYearMap.has(countryKey)) {
          const currentValue = countryYearMap.get(countryKey);
          if (currentValue || currentValue === 0) {
            countryYearMap.set(countryKey, currentValue + item.QUANTITY);
          }
        } else {
          countryYearMap.set(countryKey, item.QUANTITY);
        }
      }
    });

    const combinedMap = new Map<string, number>();

    countryYearMap.forEach((value, key) => {
      combinedMap.set(key, value);
    });

    return combinedMap;
  }

  function processAndSortData(
    data: TradeData[],
    sourcesHist: Set<string>,
    sourcesPlan: Set<string>,
    kpiSet: Set<string>,
  ): ProcessedDataItem[] {
    const totalQuantityByYear = getTotalQuantityByCountry(data, sourcesHist, sourcesPlan, kpiSet);

    const processedData: ProcessedDataItem[] = [];

    totalQuantityByYear.forEach((value, key) => {
      const [country, type] = key.split('-');
      processedData.push({ country, type, value });
    });

    processedData.sort((a, b) => {
      if (a.type === actuals && b.type !== actuals) {
        return -1;
      }
      if (a.type !== actuals && b.type === actuals) {
        return 1;
      }
      return a.country.localeCompare(b.country);
    });

    return processedData;
  }

  const renewableprocessedData = processAndSortData(
    commodityNewFilteredValueCurrentYear,
    historicalSources,
    planningSources,
    KpiForRenewable,
  );

  const nonrenewableprocessedData = processAndSortData(
    commodityNewFilteredValueCurrentYear,
    historicalSources,
    planningSources,
    KpiForNonRen,
  );

  const residualprocessedData = processAndSortData(
    commodityNewFilteredValueCurrentYear,
    historicalSources,
    planningSources,
    KpiForResGrid,
  );

  const KpiForNaturalGasRenewable = new Set([EXTERNAL_GAS_SALES_VOLUMES_RENEWABLE]);
  const KpiForNaturalGasPipeline = new Set([
    EXTERNAL_GAS_SALES_VOLUMES_PIPELINE,
    INLAND_SALES_VOLUME_NATURAL_GAS,
  ]);

  const naturalGasRenewablprocessedData = processAndSortData(
    commodityNewFilteredValueCurrentYear,
    historicalSources,
    planningSources,
    KpiForNaturalGasRenewable,
  );

  const naturalGasPipelineprocessedData = processAndSortData(
    commodityNewFilteredValueCurrentYear,
    historicalSources,
    planningSources,
    KpiForNaturalGasPipeline,
  );

  const uniqueKPIsSet = new Set(commodityNewFilteredValueCurrentYear.map((item) => item.KPI));
  const uniqueKPIs = Array.from(uniqueKPIsSet);

  const processAndSortKPI = (kpiSet: Set<string>) => {
    return processAndSortData(
      commodityNewFilteredValueCurrentYear,
      historicalSources,
      planningSources,
      kpiSet,
    );
  };
  // Create KPI sets dynamically based on uniqueKPIs
  // const kpiSets = uniqueKPIs.map(kpi => new Set([kpi]));

  // Process and sort data for each KPI set
  const processedDataByKPI: KeyValueData[] = uniqueKPIs.map((kpiSet) => ({
    key: kpiSet,

    value: processAndSortKPI(new Set([kpiSet])),
  }));

  let combinedArrayWithKey: { name: string; data: ProcessedDataItem[] }[] = [
    // ... other objects
  ];

  if (commodityFilteredValue.some((item) => item.TRADE_COMMODITY_NAME === power)) {
    combinedArrayWithKey = [
      {
        name: 'Residual-Grid',
        data: residualprocessedData,
      },
      {
        name: 'Non-Renewable',
        data: nonrenewableprocessedData,
      },
      {
        name: 'Renewable',
        data: renewableprocessedData,
      },
    ];
  } else if (commodityFilteredValue.some((item) => item.TRADE_COMMODITY_NAME === naturalGas)) {
    combinedArrayWithKey = [
      {
        name: 'External Gas Sales Volumes (Pipeline)',
        data: naturalGasPipelineprocessedData,
      },
      {
        name: 'External Gas Sales Volumes (Renewable)',
        data: naturalGasRenewablprocessedData,
      },
    ];
  } else {
    combinedArrayWithKey = processedDataByKPI.map((dataObj) => {
      return {
        name: dataObj.key,
        data: dataObj.value,
      };
    });
  }

  const combinedDataArray: ProcessedDataItem[] = processedDataByKPI.flatMap(
    (dataObj) => dataObj.value,
  );

  interface DataItem {
    country: string;
    type: string;
    value: number;
  }

  function sortByTotalValue(data: DataItem[]): DataItem[] {
    // Create an object to store the total values for each country
    const countryTotals: { [key: string]: number } = {};

    // Calculate the total value for each country
    data.forEach((item) => {
      const { country, value } = item;
      countryTotals[country] = (countryTotals[country] || 0) + value;
    });

    // Sort the data based on the total value in descending order
    return data.sort((a, b) => {
      const totalA = countryTotals[a.country] || 0;
      const totalB = countryTotals[b.country] || 0;

      // Compare total values in descending order
      if (totalA > totalB) {
        return -1;
      }
      if (totalA < totalB) {
        return 1;
      }

      // If total values are the same, preserve the original order
      return 0;
    });
  }

  const sortedData = sortByTotalValue(combinedDataArray);

  const reducedArray = sortedData.map(({ country, type }) => ({ country, type }));
  const countryTypeSet = new Set(reducedArray.map(({ country, type }) => `${country}-${type}`));

  // Convert Set back to an array
  const uniqueYearTypeArray = Array.from(countryTypeSet);
  // Convert the sorted array back to an array of objects
  const resultYearArray = uniqueYearTypeArray.map((key) => {
    const [country, type] = key.split('-');
    return {
      country,
      type,
    };
  });

  // Create an array of values with matching country and types
  const mapMissingData = (
    data: YearProcessedDataItem[],
    processedData: ProcessedDataItem[],
  ): ProcessedDataItem[] => {
    return data.map(({ country, type }) => {
      const matchingItem = processedData.find(
        (item) => item.country === country && item.type === type,
      );
      return matchingItem ?? { type, value: 0, country };
    });
  };

  // Process the data for each array in combinedArrayWithKey
  const processedCombinedArrayWithKey = combinedArrayWithKey.map((dataObj) => ({
    type: 'bar',
    name: dataObj.name,
    data: mapMissingData(resultYearArray, dataObj.data).map((x) => x.value),
  }));

  const dataArrayWithTotal = processedCombinedArrayWithKey.map((item) => {
    let total = 0;
    if (Array.isArray(item.data)) {
      total = item.data.reduce((sum, value) => sum + (typeof value === number ? value : 0), 0);
    } else if (typeof item.data === number) {
      total = item.data;
    }
    return {
      ...item,
      total,
    };
  });
  const processedCombinedSortArrayWithKey: Highcharts.SeriesBarOptions[] = dataArrayWithTotal.map(
    (dataObj, index) => ({
      type: 'bar',
      name: getRename(dataObj.name),
      data: dataObj.data,
      color: getColorByIndex(index, commodityVal),
    }),
  );

  const chartSubtitleKPI =
    commodityVal === power ? `${RENEWABLE}, ${NONRENEWABLE}, and ${RESIDUAL_GRID}` : 'Gas Volume';

  const options = highchartOption({
    chartSubtitleKPI,
    resultYearArray,
    processedCombinedSortArrayWithKey,
  });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [chartKey, setChartKey] = useState(0);

  const refreshChart = () => {
    setChartKey((prevKey) => prevKey + 1);
  };

  const prevCommodityFilteredValue = useRef<string | null>(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const currentStringified = JSON.stringify(commodityFilteredValue);
    if (currentStringified !== prevCommodityFilteredValue.current) {
      refreshChart();
      prevCommodityFilteredValue.current = currentStringified;
    }
  }, [commodityFilteredValue]);
  return (
    <div style={{ width: '100%', marginTop: '10px' }}>
      <Chart
        chartKey={`CountryBreakdownfor${currentYear}`}
        key={chartKey}
        highcharts={Highcharts}
        options={options}
        source={concatenatedSources}
      />
    </div>
  );
};

const MemoizedCountryBreakdownChart = React.memo(CountryBreakdownChart);

export default MemoizedCountryBreakdownChart;
