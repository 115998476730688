import React from 'react';
import { BiaReportLoader } from './BiaReportLoader/BiaReportLoader';
import { BiaReportTable } from './BiaReportTable/BiaReportTable';
import { IBiaReportTableData } from '../../../../Types/types';

interface IBiaReportTable {
  description: string;
  isLoading: boolean;
  loadingText: string;
  biaData: IBiaReportTableData;
}

export const BiaTable = ({ description, isLoading, loadingText, biaData }: IBiaReportTable) => (
  <div style={{ paddingBottom: '10px' }} data-testid='bia-report-table-container'>
    <h2 style={{ fontWeight: 'bold', marginBottom: '10px' }}>{description}</h2>
    {isLoading ? (
      <BiaReportLoader loadingText={loadingText} />
    ) : (
      <BiaReportTable biaData={biaData} />
    )}
  </div>
);
