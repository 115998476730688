import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AuthService } from './AuthService';
import jwt_decode from 'jwt-decode';
import RoleBasedUser from '../../CarbonDashboard/components/RoleBasedUser';
import { IUserInfo, AuthResponse } from '../../CarbonDashboard/Types/types';
import appInsights from '../appInsights';
import { AppContext } from '../../Tanso/Context/AppContext';
import { allowedUserMails } from '../../Tanso/api/constants';

const AppContent: React.FC = () => {
  const authService = useMemo(() => new AuthService(), []);
  const [user, setUser] = useState<IUserInfo>({} as IUserInfo);
  const [role, setRole] = useState('');
  //Tanso dispatch
  const { dispatch } = useContext(AppContext).appData;

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const login = async () => {
    await authService.login();
  };

  const logout = async () => {
    localStorage.setItem('activeTab', 'Dashboard');
    appInsights.trackEvent({
      name: 'User logged out',
      properties: {
        email: user?.email,
        username: user?.preferred_username,
        role,
      },
    });
    localStorage.removeItem('isAlreadyLoggedInStorage');
    await authService.logout();
  };

  const setUpRoleBasedAccess = (currentUser: AuthResponse) => {
    const userRoles = currentUser.realm_access.roles.map((userRole: string) =>
      userRole.toUpperCase(),
    );
    const cpmDashboardRole: string[] = userRoles.filter((item: string) => item.startsWith('CPM'));
    const updatedUserRole = cpmDashboardRole.length === 0 ? 'unauthorized' : cpmDashboardRole[0];
    setRole(updatedUserRole);
  };

  const getUser = () => {
    authService
      .getUser()
      .then((userDetails) => {
        if (userDetails) {
          const currentUser: IUserInfo = jwt_decode(userDetails.access_token);
          setUser(currentUser);
          dispatch({ type: 'setUserMail', payload: currentUser.email });
          const matchedTansoUser = allowedUserMails.find(
            (item: { userMail: string; userRegion: string }) => item.userMail === currentUser.email,
          );

          if (matchedTansoUser) {
            dispatch({ type: 'setUserRegions', payload: matchedTansoUser.userRegion });
          }
          setUpRoleBasedAccess(currentUser);
        }
      })
      .catch(() => {
        // Catch error here
      });
  };

  return <RoleBasedUser role={role} user={user} login={login} logout={logout} />;
};

export default AppContent;
