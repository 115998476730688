import React from 'react';
import { Title, SubTitle } from './NcfReportingHomeHeader.style';
import { Breadcrumb } from '@sede-x/shell-ds-react-framework';

const NcfReportingHomeHeader = ({
  title,
  description,
  breadcrumbItems = [],
}: {
  title: string;
  description: string;
  breadcrumbItems?: { name: string; path?: string }[];
}) => {
  return (
    <div style={{ marginTop: '46px' }}>
      <Breadcrumb items={breadcrumbItems} />
      <Title data-testid='title'>{title}</Title>
      <SubTitle data-testid='subtitle'>{description}</SubTitle>
    </div>
  );
};

export default NcfReportingHomeHeader;
