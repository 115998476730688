import { IFilterOptions, ITradeDataRegion } from '../../Types/types';
import { SE_GLOBAL_SBTI } from '../../api/constants';
import { regionOptions } from '../../constants';

//filter target data by region
export const filterTargetDataByRegion = (
  region: string,
  carbonIntensityTarget: ITradeDataRegion[],
) => {
  const carbonIntensityData = carbonIntensityTarget;
  let firstFilteredData = [];
  if (Array.isArray(carbonIntensityData)) {
    const filteredData = carbonIntensityData.filter((item) => {
      return item.REGION.includes(region);
    });
    if (filteredData.length > 0) {
      firstFilteredData = [
        {
          year: filteredData[0].TRADE_YEAR.toString(),
          type: 'Target',
          total_value: filteredData[0].TARGET_VALUE,
          region: region === SE_GLOBAL_SBTI ? 'Global' : region,
        },
      ];
    } else {
      firstFilteredData = [
        {
          year: 2030,
          type: 'Target',
          total_value: 0,
          region: region === SE_GLOBAL_SBTI ? 'Global' : region,
        },
      ];
    }
  } else {
    firstFilteredData = [{ year: '0', type: 'Target', total_value: 0 }];
  }
  return firstFilteredData;
};

export const getFilterOptions = (data: IFilterOptions[], key: keyof IFilterOptions): string[] => {
  return data
    .flatMap((val) => (val[key] !== null ? [val[key] as string] : []))
    .filter((v, index, self) => self.indexOf(v) === index);
};
export const getRegionFromSeIpu = (seIpu: string) => {
  const index = regionOptions.findIndex((item) => seIpu.includes(item));
  return index === 0 ? SE_GLOBAL_SBTI : regionOptions[index]?.toUpperCase();
};

export const getRename = (name: string) => {
  if (
    ['External Gas Sales Volumes (Pipeline)', 'Inland Sales Volumes - Pipeline Gas'].includes(name)
  ) {
    return 'Pipeline Gas';
  } else if (name === 'External Gas Sales Volumes (Renewable)') {
    return 'Renewable Gas';
  } else {
    return name;
  }
};

export const convertToReportedLatestEstimate = (data: string): string => {
  const sourceArray = data?.split(',');
  const updatedData = Array.from(
    new Set(
      sourceArray.map((item) =>
        item.includes('LE_SE') || item.includes('LE_SET') ? ' Reported Latest Estimate Fig' : item,
      ),
    ),
  );
  return updatedData.toString();
};
