import { WELCOME_TITLE } from '../../../constants';
import { CDashboard, Title } from '../CarbonDashBoard.styles';
const WelcomeDashboard = () => {
  return (
    <CDashboard test-id=''>
      <Title>{WELCOME_TITLE}</Title>
    </CDashboard>
  );
};
export default WelcomeDashboard;
