import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ICustomColumn, ImportedDeals } from '../../../Types/types';
import { getColumns, getTransformedImportedDeals } from '../../ImportedDeals/importedDealsUtils';
import ImportedDealsTable from '../../ImportedDealTable/ImportedDealsTable';
import { Card } from '@sede-x/shell-ds-react-framework';
import {
  setAddedImportedDealsId,
  setImportedDealsData,
} from '../../../redux/dashboard/dashboardSlice';
import { ALL_COUNTRIES, ALL_REGIONS } from '../../../api/constants';
import { getImportedDeals } from '../../../api';
import { currentYear } from '../../../utils/utils';

const DealFunnel = (props: { selectedRegion: string; selectedSubRegion: string }) => {
  const { selectedSubRegion, selectedRegion } = props;
  const dispatch = useAppDispatch();
  const { importedDeals } = useAppSelector((state) => state.dashboard);
  const [updatedImportedDeals, setUpdatedImportedDeals] = useState<ImportedDeals[]>([]);
  const transformImportedDeals = useCallback(
    (deals: ImportedDeals[]) => {
      const filterFutureYearDeals = deals?.filter((deal) => {
        const startYear = new Date(deal.START_DATE).getFullYear();
        return startYear >= currentYear;
      });
      const selectedSubRegionDeals =
        selectedRegion === ALL_REGIONS && selectedSubRegion === ALL_COUNTRIES
          ? filterFutureYearDeals
          : filterFutureYearDeals.filter((deal) => {
              if (selectedSubRegion === ALL_COUNTRIES) {
                return deal.REGION_NAME === selectedRegion;
              } else {
                return deal.COUNTRY_NAME === selectedSubRegion;
              }
            });
      return getTransformedImportedDeals(selectedSubRegionDeals) as ImportedDeals[];
    },
    [selectedRegion, selectedSubRegion],
  );
  const getImportedDealsData = useCallback(async () => {
    const importedDealsData = await getImportedDeals();
    setUpdatedImportedDeals(transformImportedDeals(importedDealsData.data));
    dispatch(setImportedDealsData(importedDealsData.data));
  }, [dispatch, transformImportedDeals]);

  useEffect(() => {
    if (!importedDeals.length) {
      getImportedDealsData();
    }
  }, [getImportedDealsData, importedDeals]);

  useEffect(() => {
    setUpdatedImportedDeals(transformImportedDeals(importedDeals));
    dispatch(setAddedImportedDealsId([]));
  }, [importedDeals, transformImportedDeals, getImportedDealsData, dispatch]);

  const handleToggle = (selectedItem: ImportedDeals) => {
    const updatedData = updatedImportedDeals.map((item: ImportedDeals) =>
      item.OPPORTUNITY_ID === selectedItem.OPPORTUNITY_ID
        ? { ...item, ADD_TO_CHART: selectedItem.ADD_TO_CHART === 0 ? 1 : 0 }
        : item,
    );
    const addedImportedDealsIdIds = updatedData
      .filter((item: ImportedDeals) => item.ADD_TO_CHART === 1)
      .map((item: ImportedDeals) => item.OPPORTUNITY_ID);
    dispatch(setAddedImportedDealsId(addedImportedDealsIdIds));
    setUpdatedImportedDeals(updatedData);
  };
  return (
    <Card
      data-testid='deal-funnel'
      header={{
        title: 'Imported Deals in carbon dashboard',
        description: `No. of Imported Deals: ${updatedImportedDeals.length}`,
      }}
    >
      <ImportedDealsTable
        importedDeals={updatedImportedDeals}
        cols={getColumns(null, undefined, handleToggle) as ICustomColumn[]}
      />
    </Card>
  );
};

export default DealFunnel;
