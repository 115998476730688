import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CommodityDetailsState,
  CommodityFilteredValue,
  ICommodityDetailsSlice,
  IPayload,
} from './commodityDetails.types';
import { Dayjs } from 'dayjs';
import { EventValue, ITradeDataRegion } from '../../Types/types';
import { GroupedDataItem } from '../../components/charts/CarbonIntensityChart/types';

export const initialState: CommodityDetailsState = {
  commodityVal: 'Power',
  masterCommodityFilteredValue: [],
  nonCommodityFilteredValue: [],
  commodityFilteredValue: [],
  sauVal: '',
  evpVal: '',
  seIpuRegionVal: '',
  ipuVal: '',
  vpVal: '',
  bizVal: '',
  commodityId: '',
  sauId: '',
  evpId: '',
  seIpuRegionId: '',
  ipuId: '',
  vpId: '',
  bizId: '',
  commodityOptions: [],
  sauOptions: [],
  evpOptions: [],
  seIpuRegionOptions: [],
  ipuOptions: [],
  vpOptions: [],
  businessOptions: [],
  firstDate: null,
  lastDate: null,
  disableHandleFilter: true,
  isHandleApply: false,
  sauData: [],
  evpData: [],
  seIpuRegionData: [],
  ipuData: [],
  vpData: [],
  bizData: [],
  isFetchingEVP: false,
  isFetchingSeIpuRegion: false,
  isFetchingIPU: false,
  isFetchingVP: false,
  isFetchingBiz: false,
  loadingData: false,
  isDisabledSau: false,
  isDisabledEVP: true,
  isDisabledSeIpuRegion: true,
  isDisabledIPU: true,
  isDisabledVP: true,
  isDisabledBiz: true,
  yearsRange: [null, null],
  carbonIntensityTarget: [],
  carIntTargetPerRegion: [],
  isLoading: true,
};
type TPrimitive = string | number | boolean;
type TPrimitive2 = TPrimitive | string[] | null;
type TDate =
  | Date
  | [EventValue<Dayjs>, EventValue<Dayjs>]
  | [EventValue<Dayjs | null>, EventValue<Dayjs | null>];
type TCustomDataType = CommodityFilteredValue[] | GroupedDataItem[] | TDate;

interface CommodityDetailsStateWithIndex extends CommodityDetailsState {
  [key: string]: TPrimitive2 | TCustomDataType | ITradeDataRegion[];
}

const commodityDetailsSlice = createSlice({
  name: 'commodityDetailsSlice',
  initialState,
  reducers: {
    setField: (
      state: CommodityDetailsStateWithIndex,
      action: PayloadAction<{ [key: string]: IPayload } | { key: string; value: IPayload }>,
    ) => {
      if ('key' in action.payload) {
        // If payload has 'key' and 'value' properties, update state based on key-value pair
        const { key, value } = action.payload as unknown as { key: string; value: string[] };
        if (Object.hasOwn(state, key)) {
          state[key] = value;
        }
      } else {
        // If payload is an object without 'key' and 'value' properties, update state based on key-value pairs
        const payloadKeys = Object.keys(action.payload as unknown as ICommodityDetailsSlice);
        payloadKeys.forEach((key) => {
          if (Object.hasOwn(state, key)) {
            state[key] = (action.payload as { [key: string]: string | number })[key];
          }
        });
      }
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const { setField, resetState } = commodityDetailsSlice.actions;

export default commodityDetailsSlice.reducer;
