import React, { useContext, useEffect } from 'react';
import { Grid, Flexbox, Divider } from '@sede-x/shell-ds-react-framework';
import 'leaflet/dist/leaflet.css';
import PowerNcfVolumeChart from './AnalyticsHomeCharts/PowerNcfVolumeChart/PowerNcfVolumeChart';
import PowerNCFVolumeMap from './AnalyticsHomeCharts/PowerNcfVolumeMap/PowerNcfVolumeMap';
import TradeNCounterPartyCards from './TradeNCounterPartyCards/TradeNCounterPartyCards';
import DateSideCard from './DateSideCard/DateSideCard';
import { AppContext } from '../../../../Context/AppContext';
import RegionVolumeChart from './AnalyticsHomeCharts/RegionVolumeChart/RegionVolumeChart';
import NcfReportingHomeHeader from '../NcfReportingUtils/NcfReportingHomeHeader/NcfReportingHomeHeader';
import { AnalyticsFilterSection } from './AnalyticsFilterSection';
import { REGION_NAMES } from '../../../../api/constants';

export const AnalyticsHome = () => {
  const { dispatch: appDataDispatch } = useContext(AppContext).appData;
  const { state: analyticsState } = useContext(AppContext).analyticsHome;

  useEffect(() => {
    appDataDispatch({ type: 'setCurrentPage', payload: '' });
  }, [appDataDispatch]);

  const filteredRegions = analyticsState.filteredValues?.region?.length
    ? analyticsState.filteredValues.region
    : Object.values(REGION_NAMES);

  return (
    <div style={{ height: '100%', background: '#F5F5F5' }} data-testid='analytics-home'>
      <Flexbox gap='24px 24px' style={{ marginLeft: '15px', justifyContent: 'start' }}>
        <AnalyticsFilterSection />
        <Grid gap='10px'>
          <Grid.Cell>
            <Flexbox justifyContent='space-between'>
              <NcfReportingHomeHeader
                title='Historic Gas & Power Volume Report'
                description='Chart & Table view for Historic Gas and Power Volume'
              />
              <DateSideCard />
            </Flexbox>
          </Grid.Cell>
          <Divider size='medium' direction='horizontal' />
          <Grid.Cell>
            <TradeNCounterPartyCards regions={filteredRegions} />
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox gap='10px'>
              <PowerNCFVolumeMap />
              <PowerNcfVolumeChart />
            </Flexbox>
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox gap='10px' flexWrap='wrap'>
              {filteredRegions?.map((region) => (
                <RegionVolumeChart region={region} key={region} />
              ))}
            </Flexbox>
          </Grid.Cell>
        </Grid>
      </Flexbox>
    </div>
  );
};
