import React, { useContext, useState } from 'react';
import {
  FrameParent,
  TimeStampCard,
  TimeStampCardInner,
  Header,
  TitleGroup,
  Text4,
  Timetime,
  Title,
  Timetime1,
  Footer,
  FrameContainer,
  Tag20,
  Label,
  Text7,
  Tag201,
  Placeholder2,
} from './DataReconciliationSideCard.style';
import { AppContext } from '../../../../../../Context/AppContext';
import DataIngestionAlertModal from '../ReconIngetionFailureModal/ReconIngetionFailureModal';

const DataReconciliationSideCard = () => {
  const { state: reconState } = useContext(AppContext).reconReports;
  const { ETRM_DATE, TANSO_DATE, LOG } = reconState.reconReportLastRefreshedDate;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenModal = (description: string | null, heading: string | null) => {
    if (description && heading) {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderLogStatus = () => {
    if (LOG.RUN_STATUS === 'Failed') {
      return (
        <Tag20>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M13.2894 2.73608C12.7127 1.75463 11.2873 1.75464 10.7105 2.73608L0.704992 19.7632C0.109547 20.7765 0.867002 22 1.99444 22H22.0055C23.133 22 23.8904 20.7765 23.295 19.7632L13.2894 2.73608ZM2.8856 20L12 4.48942L21.1144 20H2.8856ZM11.5 18C11.2238 18 11 17.7761 11 17.5V16.5C11 16.2239 11.2238 16 11.5 16L12.5 16C12.7761 16 13 16.2239 13 16.5V17.5C13 17.7761 12.7761 18 12.5 18H11.5ZM12.5 9C12.7761 9 13 9.22386 13 9.5L13 13.5C13 13.7761 12.7761 14 12.5 14H11.5C11.2238 14 11 13.7761 11 13.5L11 9.5C11 9.22386 11.2238 9 11.5 9H12.5Z'
              fill='#DD1D21'
            />
          </svg>
          <Label>
            <Text7 data-testid="tanso-date-error" onClick={() => handleOpenModal(LOG.ERROR_DESCRIPTION, LOG.ERROR_HEADING)}>
              Tanso AM: {TANSO_DATE}
            </Text7>
          </Label>
        </Tag20>
      );
    } else {
      return (
        <Tag201>
          <Text7 data-testid="tanso-date">Tanso AM: {TANSO_DATE}</Text7>
        </Tag201>
      );
    }
  };

  return (
    <FrameParent style={{ marginTop: '46px', marginRight: '20px' }}>
      <TimeStampCard>
        <TimeStampCardInner>
          <Header>
            <TitleGroup>
              <Text4>
                <Timetime />
                <Title>Trade Capture Date</Title>
              </Text4>
            </TitleGroup>
            <Timetime1 />
          </Header>
          <Footer>
            <FrameContainer>
              {LOG && TANSO_DATE && ETRM_DATE ? (
                <>
                  {renderLogStatus()}
                  <Tag201>
                    <Text7 data-testid="etrm-date">ETRM AM: {ETRM_DATE}</Text7>
                  </Tag201>
                </>
              ) : (
                <Text7>Data not available</Text7>
              )}
            </FrameContainer>
            <Placeholder2 />
          </Footer>
        </TimeStampCardInner>
      </TimeStampCard>
      <DataIngestionAlertModal open={isModalOpen} closeModal={closeModal} log={LOG} />
    </FrameParent>
  );
};

export default DataReconciliationSideCard;