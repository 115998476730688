import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Flexbox,
  Divider,
  Button,
} from '@sede-x/shell-ds-react-framework';
import 'leaflet/dist/leaflet.css';
import LineChart from './SnapshotLineChart/SnapshotLineChart';
import { SnapshotReportFilterSection } from './SnapshotReportFilterSection/SnapshotReportFilterSection';
import { AppContext } from '../../../../Context/AppContext';
import SnapshotWaterfallChart from './SnapshotWaterfallChart/SnapshotWaterfallChart';
import NcfReportingHomeHeader from '../NcfReportingUtils/NcfReportingHomeHeader/NcfReportingHomeHeader';
import { getAnalyticsSnapshotReportsData } from '../../../../api/services';
import { SelectFormField } from '../../../ReUsableComponents/SelectFormField/SelectFormField';

export const SnapshotReport = () => {
  const [reportSelected, setReportSelected] = useState<string[]>([]);
  const { dispatch: appDataDispatch } = useContext(AppContext).appData;
  const { state: analyticsState, dispatch: snapshotDispatch } = useContext(
    AppContext
  ).analyticsSnapshotReport;
  const snapshotReportOptions =
    analyticsState.analyticsSnapshotReportFilterOptions.snapshotReports;

  useEffect(() => {
    appDataDispatch({ type: 'setCurrentPage', payload: '' });
  }, [appDataDispatch]);

  useEffect(() => {
    const fetchSnapshotReportsData = async () => {
      try {
        const {
          year,
          quarter,
          month,
          region,
          countryCode,
          commodity,
          reportStatus,
          transactionType,
        } = analyticsState.analyticsSnapshotReportFilteredValues;
        const payload = {
          year,
          quarter,
          month,
          region,
          countryCode,
          commodity,
          reportStatus,
          transactionType
        };
        const response = await getAnalyticsSnapshotReportsData(payload);
        snapshotDispatch({
          type: 'setAnalyticsSnapshotReportsData',
          payload: response.data,
        });
      } catch (error) {
        // Log error once toast feature is implemented
      }
    };

    fetchSnapshotReportsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshotDispatch, analyticsState.analyticsSnapshotReportFilteredValues]);

  const handleApplyFilters = () => {
    const payload = {
      ...analyticsState.analyticsSnapshotReportFilteredValues,
      snapshotReports: reportSelected,
    };

    snapshotDispatch({
      type: 'setAnalyticsSnapshotReportFilteredValues',
      payload,
    });
  };

  const filteredRegions =
    analyticsState.analyticsSnapshotReportFilteredValues.region;
  return (
    <div
      style={{ height: '100%', background: '#F5F5F5' }}
      data-testid="snapshot-report"
    >
      <Flexbox
        gap="24px 24px"
        style={{ marginLeft: '15px', justifyContent: 'start' }}
      >
        <SnapshotReportFilterSection />
        <Grid gap="10px">
          <Grid.Cell>
            <Flexbox justifyContent="space-between">
              <NcfReportingHomeHeader
                title="Data Snapshot Report"
                description="Data Snapshot Report"
              />
            </Flexbox>
          </Grid.Cell>
          <Divider size="medium" direction="horizontal" />
          <Grid.Cell>
            <div data-testid="filter" style={{ width: '60%' }}>
              <Flexbox flexDirection="row" alignItems="center" gap="20px">
                <SelectFormField
                  id="report"
                  label="Select the report to view data"
                  testId="Report"
                  value={reportSelected}
                  onChange={setReportSelected}
                  options={snapshotReportOptions}
                  mode="multiple"
                />
                <Button
                  className="getDataBtn"
                  data-testid="Generate Data"
                  onClick={handleApplyFilters}
                  style={{
                    marginTop: '10px',
                    width: '25%',
                  }}
                  size="medium"
                >
                  Generate Data
                </Button>
              </Flexbox>
            </div>
          </Grid.Cell>
          <Grid.Cell>
            <LineChart />
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox gap="10px" flexWrap="wrap">
              {filteredRegions.map(region => (
                <SnapshotWaterfallChart region={region} key={region} />
              ))}
            </Flexbox>
          </Grid.Cell>
        </Grid>
      </Flexbox>
    </div>
  );
};
