import React, { useContext, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { PPASelectFormField } from '../../../../PPAInsights/PPAInsightFilterSection/PPASelectFormField';
import { AppContext } from '../../../../../../Context/AppContext';

export const ReconDataSourceFilter = () => {
  const [dataSourceSelected, setDataSourceSelected] = useState('AM_NG');
  const [generatedData, setGeneratedData] = useState('');
  const { state: reconState, dispatch: reconDispatch } = useContext(AppContext).reconReports;

  const handleGenerateData = () => {
    // Mocking data generation based on the selected report
    if (dataSourceSelected) {
      let tradeType = 'PW';
      let transactionType = 'SELL';
      if (dataSourceSelected === 'AM_NG' || dataSourceSelected === 'AU_NG') {
        tradeType = 'NG';
      }

      if (dataSourceSelected === 'EU_GROUP') {
        transactionType = 'NET';
      }

      const payload = {
        ...reconState.ReconfilteredValues,
        dataSource: dataSourceSelected,
        year: ['2024'],
        uom: 'TWh',
        transactionType,
        tradeType,
        reportType: 'DELTA_DETAILS',
        pageNumber: 1,
        pageSize: 10,
      };
      reconDispatch({ type: 'setReconFilteredValues', payload });
    } else {
      setGeneratedData('Please select a report to generate data.');
    }
  };

  // Hardcoded options
  const options = ['AM_NG', 'AM_PW', 'EU_ALIGN', 'EU_GROUP', 'AS_PW', 'AU_NG'];

  return (
    <div data-testid='filter' style={{ width: '60%' }}>
      <Flexbox flexDirection='row' alignItems='flex-start' gap='20px'>
        <PPASelectFormField
          id='report'
          label='Select the Data Source'
          testId='Report'
          value={dataSourceSelected}
          onChange={setDataSourceSelected}
          options={options}
        />
        <Button
          className='getDataBtn'
          data-testid='Generate Data'
          onClick={handleGenerateData}
          style={{ marginLeft: '10px', marginTop: '24px', width: '162px' }}
        >
          Generate Data
        </Button>
      </Flexbox>
      {generatedData && <div style={{ marginTop: '20px' }}>{generatedData}</div>}
    </div>
  );
};
