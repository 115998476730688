import {
  Alert,
  Button,
  Flexbox,
  FormField,
  Grid,
  Link,
  Modal,
  Option,
  Pagination,
  Select,
  DeprecatedTable,
  Sentiments,
  ButtonLink,
} from '@sede-x/shell-ds-react-framework';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../../Context/AppContext';
import '../../style.module.css';
import Loader from '../../Loader/Loader';
import { configRuleTableCols } from '../../TableData/ConfigRuleTableCols/configRuleTableCols';
import {
  CONFIGURE_PAGE_DESCRIPTION_1,
  CONFIGURE_PAGE_DESCRIPTION_2,
  I_ALIGNE_TABLE,
} from '../../../api/constants';
import { useNavigate } from 'react-router-dom';
import { ConfirmDeleteRule } from './ConfirmDeleteRule';
import ArrowCw from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components/ArrowCw';
import { IConfigRuleNewRecord, IConfigRuleTableData, OptionI } from '../../../Types/types';
import { InternalServerErrorPage } from '../InternalServerErrorPage/InternalServerErrorPage';
import { ColumnsType } from '@sede-x/shell-ds-react-framework/build/esm/components/DeprecatedTable/Table.types';
import filterOptions from '../../../api/__ignored__/filterOptions';
import {
  updateConfigRulePaginationData,
  fetchAllTradeTypes,
  handleFinalDelete,
  handleAddNewRecord,
  handleDeleteRule,
  handleValAndSaveBtnClick,
  setValAndSaveDisabled,
  setPublishBtnDisabled,
  handlePublishDataBtnClick,
  handleApplyFilter,
  handleCancelDeleteFlow,
  innerOnClose,
  isShowPagination,
  onCptySearch,
} from './ConfigRuleFunctions';

const Container = styled.div`
  width: auto;
  height: auto;
`;

const regionOptions = filterOptions.regionForSnapshotAndConfigureRuleOptions;

export const ConfigureRulesPage = () => {
  const { state, dispatch } = useContext(AppContext).appData;
  // table name is fixed to be I Align Table
  const tableName = I_ALIGNE_TABLE;

  const [totalCount, setTotalCount] = useState(0);
  const [configRulePaginationData, setConfigRulePaginationData] = useState<IConfigRuleTableData[]>(
    [],
  );
  const [isAddNewRecord, setIsAddNewRecord] = useState(false);
  const [checkedID, setCheckedID] = useState(-1);

  const [newCptyShortName, setNewCptyShortName] = useState('');
  const [newTradeType, setNewTradeType] = useState('');
  const [newMarket, setNewMarket] = useState('');
  const [newStorage, setNewStorage] = useState('');
  const [newStartDate, setNewStartDate] = useState('');
  const [newEndDate, setNewEndDate] = useState('');
  const [newChangeAttribute, setNewChangeAttribute] = useState('');
  const [newChangeFrom, setNewChangeFrom] = useState('');
  const [newChangeTo, setNewChangeTo] = useState('');
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isServErrOnEditClick, setIsServErrOnEditClick] = useState(false);
  const [isPrevAndNewMarketSame, setIsPrevAndNewMarketSame] = useState(false);
  const [isAllFieldsValidated, setIsAllFieldsValidated] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [isValAndSaveBtnClickedWithoutErrors, setIsValAndSaveBtnClickedWithoutErrors] =
    useState(false);
  const [isServiceError, setIsServiceError] = useState(false);
  const [isSearchingCpty, setIsSearchingCpty] = useState(false);
  const [isSearchingMarket, setIsSearchingMarket] = useState(false);
  const [cptyOptions, setCptyOptions] = useState<string[]>([]);
  const [storageOptions, setStorageOptions] = useState<OptionI[]>([
    { value: 'SELECT', label: 'SELECT' },
  ]);
  const [buySellOption, setBuySellOption] = useState<OptionI[]>([
    { value: 'SELECT', label: 'SELECT' },
  ]);
  const [tradeTypeOptions, setTradeTypeOptions] = useState<OptionI[]>([
    { value: 'SELECT', label: 'SELECT' },
  ]);

  const [marketSearchOptions, setMarketSearchOptions] = useState<string[]>([]);

  const [newRecord, setNewRecord] = useState<IConfigRuleNewRecord>({
    region: '',
    tableName: '',
    counterpartyShortName: '',
    tradeType: '',
    market: '',
    storage: '',
    startDate: '',
    endDate: '',
    changeAttribute: '',
    changeFrom: '',
    changeTo: '',
    createdBy: '',
    updatedBy: '',
  });

  const [isPublishFailure, setIsPublishFailure] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const [showDeleteRuleFilters, setShowDeleteRuleFilters] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showRadioButton, setShowRadioButton] = useState(false);
  const [radioChecked, setRadioChecked] = useState(-1);
  const [cptyForDelete, setCptyForDelete] = useState('');
  const [tradeTypeForDelete, setTradeTypeForDelete] = useState('');
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: 'setCurrentPage', payload: 'Configure Rules Page' });
    updateConfigRulePaginationData(1, dispatch, {
      setLoadingMessage,
      setPaginationNumber,
      setConfigRulePaginationData,
      setTotalCount,
      setIsServiceError,
    });
    fetchAllTradeTypes(tableName, setTradeTypeOptions);
  }, []);

  let cols: string[] = [];
  if (configRulePaginationData.length > 0) {
    cols = Object.keys(configRulePaginationData[0]);
  }
  return (
    <div data-testid='configure-rule-page'>
      <Grid gap='15px' areas={['description', 'actions', 'deleteFilters', 'message', 'table']}>
        <Grid.Cell area='description'>
          <p>{CONFIGURE_PAGE_DESCRIPTION_1}</p>
          <p>{CONFIGURE_PAGE_DESCRIPTION_2}</p>
          <br />
          <p>
            Should changes be required to the data on row wise, then the{' '}
            <Link>
              <ButtonLink onClick={() => navigate('/viewEditReport')}>Data Edit</ButtonLink>
            </Link>{' '}
            feature in Tanso can be used.
          </p>
        </Grid.Cell>
        <Modal
          title='Rule successfully deleted.'
          open={isDeleteSuccess}
          onClose={() => setIsDeleteSuccess(false)}
          showFooter={false}
        ></Modal>
        {!isServiceError && (
          <Grid.Cell area='actions'>
            <Flexbox justifyContent='flex-end' gap='20px 20px'>
              <Flexbox gap='10px 10px' style={styles.firstContent}>
                <ConfirmDeleteRule
                  showConfirmDeleteModal={showConfirmDeleteModal}
                  setShowConfirmDeleteModal={setShowConfirmDeleteModal}
                  handleFinalDelete={() =>
                    handleFinalDelete(dispatch, radioChecked, tradeTypeForDelete, cptyForDelete, {
                      setLoadingMessage,
                      setIsDeleteSuccess,
                      setShowConfirmDeleteModal,
                      setShowDeleteRuleFilters,
                      setShowRadioButton,
                      setTradeTypeForDelete,
                      setCptyForDelete,
                      setRadioChecked,
                      setPaginationNumber,
                      setConfigRulePaginationData,
                      setTotalCount,
                      setIsServiceError,
                    })
                  }
                />
                <FormField label='Region' style={{ width: 200 }}>
                  <Select
                    size='large'
                    allowClear={false}
                    options={regionOptions}
                    defaultValue={regionOptions[0].value}
                    disabled={true}
                  />
                </FormField>
                <Button
                  variant='outlined'
                  style={styles.refreshBtn}
                  iconOnly={true}
                  size='large'
                  icon={<ArrowCw />}
                  disabled={isAddNewRecord}
                  onClick={() =>
                    updateConfigRulePaginationData(paginationNumber, dispatch, {
                      setLoadingMessage,
                      setPaginationNumber,
                      setConfigRulePaginationData,
                      setTotalCount,
                      setIsServiceError,
                    })
                  }
                />

                <Button
                  variant='outlined'
                  size='large'
                  style={styles.addNewRecordBtn}
                  onClick={() =>
                    handleAddNewRecord(configRulePaginationData, dispatch, tableName, {
                      setLoadingMessage,
                      setStorageOptions,
                      setBuySellOption,
                      setIsAddNewRecord,
                      setConfigRulePaginationData,
                    })
                  }
                  data-testid='add-new-rule-btn'
                  disabled={isAddNewRecord || showDeleteRuleFilters}
                >
                  Add New Rule
                </Button>

                <Button
                  variant='outlined'
                  size='large'
                  style={styles.addNewRecordBtn}
                  onClick={() => handleDeleteRule(setShowDeleteRuleFilters, setShowRadioButton)}
                  data-testid='delete-rule-btn'
                  disabled={isAddNewRecord || showDeleteRuleFilters}
                >
                  Delete Rule
                </Button>
              </Flexbox>
              {!showDeleteRuleFilters && (
                <Button
                  style={styles.valAndSaveBtn}
                  onClick={() =>
                    handleValAndSaveBtnClick(
                      {
                        newCptyShortName,
                        newTradeType,
                        newMarket,
                        newStorage,
                        newStartDate,
                        newEndDate,
                        newChangeAttribute,
                        newChangeFrom,
                        newChangeTo,
                        isAddNewRecord,
                      },
                      {
                        setIsValidationError,
                        setIsValAndSaveBtnClickedWithoutErrors,
                        setIsAllFieldsValidated,
                        setIsPrevAndNewMarketSame,
                        setNewRecord,
                      },
                      state,
                    )
                  }
                  disabled={setValAndSaveDisabled(
                    isAddNewRecord,
                    isValAndSaveBtnClickedWithoutErrors,
                  )}
                >
                  Validate Changes
                </Button>
              )}
              {!showDeleteRuleFilters && (
                <Button
                  style={styles.publishDataBtn}
                  disabled={setPublishBtnDisabled(
                    isAddNewRecord,
                    isValAndSaveBtnClickedWithoutErrors,
                    checkedID,
                  )}
                  onClick={() =>
                    handlePublishDataBtnClick(dispatch, newRecord, {
                      setIsAddNewRecord,
                      setIsPublished,
                      setIsPublishFailure,
                      setIsAllFieldsValidated,
                      setNewCptyShortName,
                      setNewTradeType,
                      setNewMarket,
                      setNewStorage,
                      setNewChangeAttribute,
                      setNewChangeFrom,
                      setNewChangeTo,
                      setCheckedID,
                      setMarketSearchOptions,
                      setCptyOptions,
                      setIsValidationError,
                      setIsValAndSaveBtnClickedWithoutErrors,
                      setLoadingMessage,
                      setPaginationNumber,
                      setConfigRulePaginationData,
                      setTotalCount,
                      setIsServiceError,
                    })
                  }
                >
                  Publish New/Modified Data
                </Button>
              )}
              {showDeleteRuleFilters && (
                <Button
                  style={styles.publishDataBtn}
                  onClick={() => setShowConfirmDeleteModal(true)}
                  data-testid='confirm-delete-btn'
                  disabled={radioChecked === -1}
                >
                  Confirm Delete
                </Button>
              )}
            </Flexbox>
          </Grid.Cell>
        )}
        {showDeleteRuleFilters && (
          <Grid.Cell area='deleteFilters'>
            <Flexbox gap='10px 10px' style={styles.firstContent}>
              <FormField label='Counter Party' style={{ width: 200 }}>
                <Select
                  size='large'
                  allowClear={false}
                  placeholder='Enter min 2 characters'
                  value={cptyForDelete}
                  onSearch={(query: string) =>
                    onCptySearch(query, setIsSearchingCpty, setCptyOptions)
                  }
                  loading={isSearchingCpty}
                  onChange={(e: string) => {
                    setCptyForDelete(e);
                  }}
                  autoClearSearchValue={false}
                  onMouseEnter={() => setIsShowMessage(true)}
                  onMouseLeave={() => setIsShowMessage(false)}
                >
                  {cptyOptions.map((val: string) => {
                    return (
                      <Option key={val} value={val}>
                        {val}
                      </Option>
                    );
                  })}
                </Select>
              </FormField>
              <FormField label='Trade Type' style={{ width: 200 }}>
                <Select
                  value={tradeTypeForDelete}
                  size='large'
                  aria-label='Select'
                  allowClear={false}
                  options={tradeTypeOptions}
                  onChange={(e: string) => setTradeTypeForDelete(e)}
                />
              </FormField>
              <Button
                variant='outlined'
                size='large'
                style={styles.addNewRecordBtn}
                onClick={() =>
                  handleApplyFilter(dispatch, {
                    setLoadingMessage,
                    setPaginationNumber,
                    setConfigRulePaginationData,
                    setTotalCount,
                    setIsServiceError,
                    setRadioChecked,
                  })
                }
              >
                Apply Filters
              </Button>
              <Button
                variant='outlined'
                size='large'
                style={styles.addNewRecordBtn}
                data-testid='cancel-delete-btn'
                onClick={() =>
                  handleCancelDeleteFlow(dispatch, tradeTypeForDelete, cptyForDelete, {
                    setShowDeleteRuleFilters,
                    setShowRadioButton,
                    setTradeTypeForDelete,
                    setCptyForDelete,
                    setRadioChecked,
                    setLoadingMessage,
                    setPaginationNumber,
                    setConfigRulePaginationData,
                    setTotalCount,
                    setIsServiceError,
                  })
                }
              >
                Cancel Delete
              </Button>
            </Flexbox>
          </Grid.Cell>
        )}
        <Grid.Cell area='message'>
          <Alert
            sentiment={Sentiments.Information}
            style={{ visibility: isShowMessage ? 'visible' : 'hidden' }}
          >
            Enter min 2 characters to initiate searching for fields COUNTER PARTY SHORT NAME and
            MARKET
          </Alert>
          {isValidationError && (
            <Alert sentiment={Sentiments.Negative}>
              {isPrevAndNewMarketSame
                ? 'Change From and To for Market values can not be identical.'
                : 'All fields are mandatory.'}
            </Alert>
          )}
        </Grid.Cell>
        <Grid.Cell area='table'>
          <Modal
            title='Rule successfully published.'
            open={isPublished}
            onClose={() =>
              innerOnClose(
                isPublishFailure,
                setIsPublishFailure,
                isPublished,
                setIsPublished,
                isServErrOnEditClick,
                setIsServErrOnEditClick,
              )
            }
          ></Modal>
          <Modal
            title='We are facing trouble fetching options for editing. Please retry.'
            open={isServErrOnEditClick}
            onClose={() =>
              innerOnClose(
                isPublishFailure,
                setIsPublishFailure,
                isPublished,
                setIsPublished,
                isServErrOnEditClick,
                setIsServErrOnEditClick,
              )
            }
          ></Modal>
          <Modal
            title='Unable to publish your changes. Please try again after some time.'
            open={isPublishFailure}
            onClose={() =>
              innerOnClose(
                isPublishFailure,
                setIsPublishFailure,
                isPublished,
                setIsPublished,
                isServErrOnEditClick,
                setIsServErrOnEditClick,
              )
            }
          ></Modal>
          <div>
            <Flexbox gap='24px 24px' justifyContent='center'>
              <Grid rowGap='24px'>
                <Container>
                  {state.spinner && <Loader message={loadingMessage} />}
                  {isServiceError && <InternalServerErrorPage />}
                  {!isServiceError && (
                    <DeprecatedTable
                      data-testid='sds-table'
                      size='small'
                      data={configRulePaginationData}
                      rowKey={(record: IConfigRuleTableData) => record['RULE_CONFIG_ID']}
                      columns={
                        configRuleTableCols(
                          cols,
                          {
                            isAllFieldsValidated,
                            isAddNewRecord,
                            newCptyShortName,
                            configRulePaginationData,
                            isSearchingCpty,
                            cptyOptions,
                            newTradeType,
                            newMarket,
                            newStorage,
                            storageOptions,
                            newStartDate,
                            newChangeAttribute,
                            newChangeFrom,
                            newChangeTo,
                            buySellOption,
                            marketSearchOptions,
                            isSearchingMarket,
                            showRadioButton,
                            radioChecked,
                            tradeTypeOptions,
                          },
                          {
                            setIsAllFieldsValidated,
                            setNewCptyShortName,
                            setConfigRulePaginationData,
                            setIsAddNewRecord,
                            setIsSearchingCpty,
                            setCptyOptions,
                            setNewTradeType,
                            setNewMarket,
                            setNewStorage,
                            setNewStartDate,
                            setNewEndDate,
                            setNewChangeAttribute,
                            setNewChangeFrom,
                            setNewChangeTo,
                            setMarketSearchOptions,
                            setIsSearchingMarket,
                            setIsShowMessage,
                            setIsValAndSaveBtnClickedWithoutErrors,
                            setIsValidationError,
                            setRadioChecked,
                          },
                          state,
                        ) as ColumnsType<IConfigRuleTableData>
                      }
                      tableLayout='fixed'
                      wrapperStyle={{ border: '2px solid rgba(0,0,0,0.15)' }}
                    />
                  )}
                </Container>
                {isShowPagination(configRulePaginationData, isAddNewRecord) && (
                  <Pagination
                    data-testid='pagination'
                    total={totalCount}
                    pageSize={5}
                    onChange={(pageNumber: number) =>
                      updateConfigRulePaginationData(pageNumber, dispatch, {
                        setLoadingMessage,
                        setPaginationNumber,
                        setConfigRulePaginationData,
                        setTotalCount,
                        setIsServiceError,
                      })
                    }
                  />
                )}
              </Grid>
            </Flexbox>
          </div>
        </Grid.Cell>
      </Grid>
    </div>
  );
};

const styles = {
  firstContent: {
    marginRight: 'auto',
    marginTop: '12px',
  },
  refreshBtn: {
    margin: '24px 5px',
  },
  addNewRecordBtn: {
    margin: '24px 5px',
    height: '48px',
  },
  editRecordBtn: {
    margin: '24px 5px',
    height: '48px',
  },
  valAndSaveBtn: {
    margin: '34px 5px',
    height: '50px',
  },
  publishDataBtn: {
    margin: '34px 5px',
    height: '50px',
  },
};
