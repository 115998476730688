import React, { useEffect, useState, useContext } from 'react';
import { TooltipFormatterContextObject } from 'highcharts';
import { ChartContainer, TableHeading, NcfToggle, ScrollableDiv } from './SnapshotLineChart.style';
import {
  getCommodityName,
  fetchDataBasedOnView,
  getLineChartCategories,
  getSeriesData,
  tooltipFormatter,
  generateColumns,
  calculateCumulativeSum,
} from './SnapshotLineChartUtils';
import Chart from '../../Chart/Chart';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../Context/AppContext';
import { AnalyticsSnapshotReportLineChartItem } from '../../../../../Types/IAnalyticsSnapshotReportType';
import ChartToggle from '../../../../ReUsableComponents/PPAToggle/PPAToggle';

const SnapshotLineChart: React.FC = () => {
  const [view, setView] = useState('Chart');
  const { state: analyticsState, dispatch: snapshotDispatch } =
    useContext(AppContext).analyticsSnapshotReport;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDataBasedOnView(view, analyticsState);
        if (response) {
          snapshotDispatch({
            type: 'setAnalyticsSnapshotReportChartData',
            payload: response.data,
          });
        }
      } catch (error) {
        // Log error once toast feature is implemented
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, snapshotDispatch, analyticsState.analyticsSnapshotReportFilteredValues]);

  const mapData: AnalyticsSnapshotReportLineChartItem[] =
    analyticsState.analyticsSnapshotReportChartData;
  let series = getSeriesData(mapData);

  let yAxisTitle = `Quantity(${analyticsState?.analyticsSnapshotReportFilteredValues?.uom})`;
  //if single year selected then show cumulative sum
  if (analyticsState?.analyticsSnapshotReportFilteredValues?.year.length === 1) {
    series = calculateCumulativeSum(series);
    yAxisTitle = `Cumulative Sum(${analyticsState?.analyticsSnapshotReportFilteredValues?.uom})`;
  }

  const options = {
    xAxis: {
      categories: getLineChartCategories(mapData),
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
    series,
    tooltip: {
      shared: false,
      // eslint-disable-next-line no-unused-vars
      formatter(this: TooltipFormatterContextObject) {
        return tooltipFormatter(this, mapData);
      },
    },
  };

  return (
    <ChartContainer>
      <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
        <TableHeading>
          {getCommodityName(analyticsState?.analyticsSnapshotReportFilteredValues?.commodity[0])}{' '}
          NCF Volume
        </TableHeading>
        <NcfToggle>
          <ChartToggle setView={setView} />
        </NcfToggle>
      </Flexbox>
      {view === 'Table' ? (
        <ScrollableDiv>
          <BaseTable
            columns={generateColumns()}
            data={mapData}
            size='large'
            data-testid='base-table'
          />
        </ScrollableDiv>
      ) : (
        <Chart options={options} footer={'Source'} />
      )}
    </ChartContainer>
  );
};

export default SnapshotLineChart;
