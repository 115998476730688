import React from 'react';
import { Select, Option, Flexbox, Grid } from '@sede-x/shell-ds-react-framework';

interface ITitleProps {
  selectedYear: number | null;
  setSelectedYear: React.Dispatch<React.SetStateAction<number | null>>;
  selectedCommodity: string | null;
  setSelectedCommodity: React.Dispatch<React.SetStateAction<string | null>>;
}
const ImportedDealModalTitle: React.FC<ITitleProps> = ({
  selectedYear,
  setSelectedYear,
  selectedCommodity,
  setSelectedCommodity,
}) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear + i);
  const commodityOptions = ['Pipeline Gas', 'Power'];
  const styles = {
    titleSelect: {
      width: '250px',
      zIndex: 10,
    },
  };

  return (
    <Grid flow='column' justifyContent='space-between'>
      <Grid.Cell>
        <span>Imported Deals in Carbon Dashboard</span>
      </Grid.Cell>
      <Grid.Cell>
        <Flexbox gap='20px'>
          <Select
            size='small'
            value={selectedYear}
            onChange={(year) => setSelectedYear(year)}
            placeholder='Select to show yearly volume'
            style={styles.titleSelect}
          >
            {years.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          <Select
            size='small'
            value={selectedCommodity}
            onChange={(commodity) => setSelectedCommodity(commodity)}
            placeholder='Select Commodity'
            style={styles.titleSelect}
          >
            {commodityOptions.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Flexbox>
      </Grid.Cell>
    </Grid>
  );
};

export default ImportedDealModalTitle;
